import clone from 'clone';
import actions from './actions';

const initState = {
  questionnaires: [],
  initialQuestionnaires: false,
  currentQuestionnaire: {},
  editableQuestionnaire: {},
  isNewQuestionnaire: false,
  enableEditView: false,
  isFetching: false,
  userQuestionnaire: {},
  loading: true,
  isUserQuestionnaire: false,
  userRequisites: {},
  isUserRequisites: false,
  userBrands: [],
  isUserBrands: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  // console.log(`actions.${type}`);
  switch (type) {
    case actions.UPDATE_QUESTIONNAIRE: {
      const currentQuestionnaire = action.questionnaire
        ? action.questionnaire
        : state.currentQuestionnaire;

      return {
        ...state,
        questionnaires: action.questionnaires,
        currentQuestionnaire: clone(currentQuestionnaire),
        initialQuestionnaires: true,
        isNewQuestionnaire: false,
        enableEditView: false,
        isFetching: false,
      };
    }
    case actions.UPDATE_USER_QUESTIONNAIRE: {
      return {
        ...state,
        userQuestionnaire: action.userQuestionnaire,
      };
    }
    case actions.SELECT_CURRENT_QUESTIONNAIRE: {
      const questionnaires = state.questionnaires;
      const index = questionnaires
        .map((questionnaire) => questionnaire.id)
        .indexOf(action.id);

      if (index !== -1) {
        const currentQuestionnaire = questionnaires[index];
        return {
          ...state,
          currentQuestionnaire,
          editableQuestionnaire: clone(currentQuestionnaire),
        };
      } else
        return {
          ...state,
        };
    }
    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
        editableQuestionnaire: clone(state.currentQuestionnaire),
      };
    case actions.UPDATE_EDIT_QUESTIONNAIRE:
      const questionnaires = state.questionnaires;
      const index = questionnaires
        .map((questionnaire) => questionnaire.id)
        .indexOf(action.questionnaire.id);
      if (index !== -1) {
        questionnaires[index] = action.questionnaire;
        return {
          ...state,
          questionnaires: clone(questionnaires),
          currentQuestionnaire: clone(action.questionnaire),
          editableQuestionnaire: clone(action.questionnaire),
          isUserQuestionnaire: false,
          isUserRequisites: false,
        };
      } else
        return {
          ...state,
          isUserQuestionnaire: false,
          isUserRequisites: false,
        };
    case actions.REQUEST_POSTS_QUESTIONNAIRE:
      return {
        ...state,
        isFetching: true,
      };
    case actions.RECEIVE_POSTS_QUESTIONNAIRE:
      return {
        ...state,
        isFetching: false,
        initialQuestionnaires: true,
        isNewQuestionnaire: false,
        enableEditView: false,
        questionnaires: action.questionnaires,
      };
    case actions.FETCH_USER_QUESTIONNAIRE_START:
      return {
        ...state,
        fetchQuestionnaireStarted: action.payload,
      };
    case actions.FETCH_USER_QUESTIONNAIRE_SUCCESS:
    case actions.SUBMIT_USER_QUESTIONNAIRE_SUCCESS:
      if (action.payload.userId === parseInt(localStorage.getItem('id'))) {
        return {
          ...state,
          loading: false,
          isUserQuestionnaire: true,
          userQuestionnaire: action.payload,
          fetchQuestionnaireStarted: undefined,
        };
      } else if (action.payload.userId === 0) {
        return {
          ...state,
          loading: false,
          isUserQuestionnaire: true,
          fetchQuestionnaireStarted: undefined,
        };
      } else {
        return {
          ...state,
          loading: false,
          fetchQuestionnaireStarted: undefined,
        };
      }
    case actions.FETCH_USER_QUESTIONNAIRE_FAILURE:
    case actions.SUBMIT_USER_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        loading: false,
        userQuestionnaire: undefined,
        error: action.payload,
        fetchQuestionnaireStarted: undefined,
      };
    case actions.FETCH_USER_REQUISITES_START:
      return {
        ...state,
        userRequisites: {},
      };
    case actions.FETCH_USER_REQUISITES_SUCCESS:
    case actions.SUBMIT_USER_REQUISITES_SUCCESS:
      const userRequisites = action.payload.userRequisites;
      if (
        userRequisites.user_id === parseInt(localStorage.getItem('id')) ||
        userRequisites.user_id === state?.userQuestionnaire?.userId ||
        userRequisites.user_id === state?.currentQuestionnaire?.userId
      ) {
        return {
          ...state,
          isUserRequisites: true,
          userRequisites: userRequisites,
        };
      } else if (userRequisites.user_id === 0) {
        return {
          ...state,
          isUserRequisites: true,
        };
      } else {
        return {
          ...state,
        };
      }
    case actions.FETCH_USER_REQUISITES_FAILURE:
    case actions.SUBMIT_USER_REQUISITES_FAILURE:
      return {
        ...state,
        loading: false,
        userRequisites: undefined,
        error: action.payload,
      };

    case actions.FETCH_USER_BRANDS_START:
      return {
        ...state,
        userBrands: [],
      };
    case actions.FETCH_USER_BRANDS_SUCCESS: {
      const userBrands = action.payload.userBrands;
      return {
        ...state,
        isUserBrands: true,
        userBrands: [
          ...new Set(userBrands.GuestBrands.map((ub) => ub.brandId)),
        ],
      };
    }
    case actions.ADD_USER_BRAND_SUCCESS: {
      const brandId = action.payload.brandId;
      return {
        ...state,
        isUserBrands: true,
        userBrands: [...(state.userBrands || []), brandId],
      };
    }
    case actions.REMOVE_USER_BRAND_SUCCESS: {
      const brandId = action.payload.brandId;
      return {
        ...state,
        isUserBrands: true,
        userBrands: [...(state.userBrands || []), brandId].filter(
          (b) => b !== brandId
        ),
      };
    }
    case actions.FETCH_USER_BRANDS_FAILURE:
    case actions.ADD_USER_BRAND_FAILURE:
    case actions.REMOVE_USER_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        userBrands: [],
        error: action.payload,
      };

    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
