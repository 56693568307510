const localDataName = 'mateQuestionnaires';
const orderStatusOptions = ['Pending', 'Shipped', 'Delivered'];

const newQuestionnaire = {
  name: '',
  placeName: '',
  placeType: '',
  placeAddress: '',
  site: '',
  email: '',
  phone: '',
  mainKitchen: '',
  mainMenu: '',
  mainFood: '',
  cooksCount: 0,
  additionalEquipment: '',
  equipment: [],
  fileLinks: [],
};

export { localDataName, newQuestionnaire, orderStatusOptions };
