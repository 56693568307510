import stopListsActions from './actions';

const INITIAL_DATA = {
  productRestaurants: {},
  data: null,
  loading: true,
  error: null,
};
export default function stopListsReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case stopListsActions.FETCH_STOP_LISTS_DATA_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case stopListsActions.FETCH_STOP_LISTS_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case stopListsActions.FETCH_STOP_LISTS_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case stopListsActions.SET_STOP_LISTS_ITEM_SUCCESS:
      const { id, status } = action.payload;
      const stateStopList = state.data?.stopList || [];
      let stopList;
      if (!status) {
        stopList = stateStopList.concat({ id });
      } else {
        stopList = stateStopList.filter((listId) => listId.id !== id);
      }
      return {
        ...state,
        data: {
          ...state.data,
          stopList: stopList,
        },
      };
    case stopListsActions.SET_STOP_LISTS_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case stopListsActions.FETCH_STOP_LISTS_ITEM_RESTAURANTS: {
      return {
        ...state,
        data: {
          ...state.data,
          stopListProductId: action.payload,
          productRestaurants: undefined,
        },
        productRestaurantsLoading: true,
      };
    }
    case stopListsActions.FETCH_STOP_LISTS_ITEM_RESTAURANTS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          stopListProductId: action.payload.productId,
          productRestaurants: action.payload.restaurants,
        },
        productRestaurantsLoading: false,
      };
    }
    case stopListsActions.FETCH_STOP_LISTS_ITEM_RESTAURANTS_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          stopListProductId: action.payload.productId,
          productRestaurants: undefined,
        },
        error: action.payload.error,
        productRestaurantsLoading: false,
      };
    }
    case stopListsActions.SET_STOP_LIST_ITEM_RESTAURANT_SUCCESS: {
      const { productId, restaurantId, status } = action.payload;

      let productRestaurants = state.data.productRestaurants || [];
      if (status) {
        productRestaurants = productRestaurants.concat([restaurantId]);
      } else {
        productRestaurants = productRestaurants.filter(
          (rid) => rid != restaurantId
        );
      }
      return {
        ...state,
        data: {
          ...state.data,
          stopListProductId: productId,
          productRestaurants: productRestaurants,
        },
        productRestaurantsLoading: false,
      };
    }

    default:
      return state;
  }
}
