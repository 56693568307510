import reportGeneratorActions from './actions';

const INITIAL_DATA = {
  data: null,
  finData: null,
  loading: true,
  error: null,
};
export default function stopListsReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case reportGeneratorActions.FETCH_REPORT_START:
      return {
        ...state,
        data: null,
        loading: true,
      };
    case reportGeneratorActions.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_FILTERS_START:
      return {
        ...state,
        finData: null,
        loading: true,
        error: null,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_SUCCESS:
      return {
        ...state,
        finData: action.payload,
        loading: false,
        error: null,
      };
    case reportGeneratorActions.FETCH_FIN_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
