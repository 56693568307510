import { all, call, takeEvery, put } from 'redux-saga/effects';
import siteConfig from '@iso/config/site.config';
import actions from './actions';
import authActions from '@iso/redux/auth/actions';

export const onGetAllPartnersRequest = async (userId) =>
  await fetch(`${siteConfig.apiUrl}/partners`)
    .then((response) => response.json())
    .then((json) => {
      return json['Partners'];
    });

export const onDeleteRequest = async (userId) =>
  await fetch(`${siteConfig.apiUrl}/deletePartner/${userId}`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchGetAllPartners() {
  try {
    let partners = yield call(onGetAllPartnersRequest);

    yield put(actions.GetAllPartnersSuccess(partners));
  } catch (error) {
    yield put(actions.GetAllPartnersFailure(error));
  }
}

function* fetchDeletePartner({ payload }) {
  try {
    const userId = payload;
    let isDelete = yield call(onDeleteRequest, userId);

    yield put(actions.deletePartnerSuccess(isDelete, userId));
  } catch (error) {
    yield put(actions.deletePartnerFailure(error));
  }
}

export default function* partnersSaga() {
  yield all([
    takeEvery(actions.REQUEST_POSTS_PARTNERS, fetchGetAllPartners),
    takeEvery(actions.DELETE_PARTNER, fetchDeletePartner),
  ]);
}

export function* logout() {
  yield takeEvery(authActions.LOGOUT, function* () {
    yield put({
      type: actions.LOGOUT,
    });
  });
}
