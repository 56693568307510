// saga.js
import profileActions from './actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import siteConfig from '@iso/config/site.config';
import { v4 as uuidV4 } from 'uuid';

async function uploadFiles(file) {
  const response = await fetch(`${siteConfig.apiUrl}/upload`, {
    crossDomain: true,
    method: 'POST',
    mode: 'cors',
    body: file,
  });
  return await response.json();
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

async function getUploadedImageUrl(url) {
  if (!url.startsWith('data:')) {
    return url;
  }
  const blob = dataURItoBlob(url);
  let ext = '';
  switch (blob.type) {
    case 'image/png':
      ext = '.png';
      break;
    case 'image/jpg':
      ext = '.jpg';
      break;
    case 'image/jpeg':
      ext = '.jpeg';
      break;
  }
  const formData = new FormData();
  formData.append('brands', blob, uuidV4() + ext);
  return uploadFiles(formData).then((result) => result.fileLinks[0]);
}

export const onBrandsRequest = async () =>
  await fetch(
    `${siteConfig.apiUrl}/brands` +
      (localStorage.getItem('userRole') === 'admin'
        ? ''
        : '/' + localStorage.getItem('restaurantId'))
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onUserBrandsRequest = async (userId) =>
  await fetch(`${siteConfig.apiUrl}/brands/` + userId)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onUserOwnBrandsRequest = async (userId) =>
  await fetch(`${siteConfig.apiUrl}/userOwnBrands/` + userId)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onRestaurantRequest = async (userId) =>
  await fetch(`${siteConfig.apiUrl}/userRestaurant/${userId}`, {
    crossDomain: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onSetRestaurantRequest = async (userId, restaurant) =>
  await fetch(`${siteConfig.apiUrl}/userRestaurant/${userId}`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(restaurant),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onAddBrand = async (newBrand) => {
  newBrand.imageUrl = await getUploadedImageUrl(newBrand.imageUrl);
  return await fetch(`${siteConfig.apiUrl}/brand/`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(newBrand),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onAddUserOwnBrand = async (newBrand, userId) => {
  if (newBrand.imageUrl !== undefined) {
    newBrand.url = await getUploadedImageUrl(newBrand.imageUrl);
  }
  return await fetch(`${siteConfig.apiUrl}/userOwnBrands/${userId}`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(newBrand),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onVerifyEmail = async (token) => {
  await fetch(`${siteConfig.apiUrl}/verify_email?token=${token}`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  });
};

function* fetchBrandsProfileDataEffect() {
  try {
    let brands = yield call(onBrandsRequest);

    yield put(profileActions.fetchProfileDataSuccess(brands));
  } catch (error) {
    yield put(profileActions.fetchProfileDataFailure(error));
  }
}

function* fetchUserBrandsProfileDataEffect({ payload }) {
  try {
    let brands = yield call(onUserBrandsRequest, payload);

    yield put(profileActions.fetchProfileDataSuccess(brands));
  } catch (error) {
    yield put(profileActions.fetchProfileDataFailure(error));
  }
}

function* fetchPartnerRestaurantDataEffect({ payload }) {
  try {
    let rest = yield call(onRestaurantRequest, payload);

    yield put(profileActions.fetchRestaurantDataSuccess(rest));
  } catch (error) {
    yield put(profileActions.fetchRestaurantDataFailure(error));
  }
}

function* fetchSetPartnerRestaurantDataEffect({ payload }) {
  const { userId, restaurant } = payload;
  try {
    let rest = yield call(onSetRestaurantRequest, userId, restaurant);

    yield put(profileActions.fetchSetRestaurantDataSuccess(rest));
  } catch (error) {
    yield put(profileActions.fetchSetRestaurantDataFailure(error));
  }
}

function* fetchAddBrand({ payload }) {
  try {
    let newBrand = yield call(onAddBrand, payload);

    yield put(profileActions.fetchAddBrandSuccess(newBrand));
  } catch (error) {
    yield put(profileActions.fetchAddBrandFailure(error));
  }
}

export const onUpdateBrand = async (brand) => {
  brand = {
    ...brand,
    titleUrl: await getUploadedImageUrl(brand.titleUrl),
    url: await getUploadedImageUrl(brand.url),
  };
  return await fetch(`${siteConfig.apiUrl}/brand/`, {
    crossDomain: true,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(brand),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

function* updateBrand({ payload }) {
  try {
    let newBrand = yield call(onUpdateBrand, payload);

    yield put(profileActions.updateBrandSuccess(newBrand));
  } catch (error) {
    yield put(profileActions.updateBrandFailure(error));
  }
}

export const onDeleteBrand = async (brand) => {
  return await fetch(`${siteConfig.apiUrl}/deleteBrand/${brand.id}`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

function* deleteBrand({ payload }) {
  try {
    let res = yield call(onDeleteBrand, payload);

    yield put(profileActions.deleteBrandSuccess(payload));
  } catch (error) {
    yield put(profileActions.deleteBrandFailure(error));
  }
}

function* fetchUserOwnBrandsDataEffect({ payload }) {
  try {
    let brands = yield call(onUserOwnBrandsRequest, payload);

    yield put(profileActions.fetchUserOwnBrandsSuccess(brands));
  } catch (error) {
    yield put(profileActions.fetchUserOwnBrandsFailure(error));
  }
}

function* fetchAddUserOwnBrand({ payload }) {
  try {
    const { brand, userId } = payload;
    let newBrand = yield call(onAddUserOwnBrand, brand, userId);

    yield put(profileActions.fetchAddUserOwnBrandSuccess(newBrand));
  } catch (error) {
    yield put(profileActions.fetchAddUserOwnBrandFailure(error));
  }
}

function* fetchVerifyEmail({ payload }) {
  try {
    const { token } = payload;
    let newBrand = yield call(onVerifyEmail, token);

    yield put(profileActions.fetchAddUserOwnBrandSuccess(newBrand));
  } catch (error) {
    yield put(profileActions.fetchAddUserOwnBrandFailure(error));
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(
      profileActions.FETCH_PROFILE_DATA_START,
      fetchBrandsProfileDataEffect
    ),
    takeEvery(
      profileActions.FETCH_USER_PROFILE_DATA_START,
      fetchUserBrandsProfileDataEffect
    ),
    takeEvery(
      profileActions.FETCH_RESTAURANT_DATA_START,
      fetchPartnerRestaurantDataEffect
    ),
    takeEvery(
      profileActions.FETCH_SET_RESTAURANT_DATA_START,
      fetchSetPartnerRestaurantDataEffect
    ),
    takeEvery(profileActions.FETCH_ADD_BRAND, fetchAddBrand),
    takeEvery(profileActions.UPDATE_BRAND, updateBrand),
    takeEvery(profileActions.DELETE_BRAND, deleteBrand),
    takeEvery(
      profileActions.FETCH_USER_OWN_BRANDS_START,
      fetchUserOwnBrandsDataEffect
    ),
    takeEvery(profileActions.FETCH_ADD_USER_OWN_BRAND, fetchAddUserOwnBrand),
    takeEvery(profileActions.FETCH_VERIFY_EMAIL_START, fetchVerifyEmail),
  ]);
}
