import React, { useState } from 'react';
import Button from '@iso/components/uielements/button';
import IntlMessages from '@iso/components/utility/intlMessages';
import PwaNotificationWrapper from './PwaNotification.styles';
import { Modal } from 'antd';
import reactIcon from '@iso/assets/images/152.png';
import { useParams } from 'react-router-dom';

let deferredPrompt;

function registerEventListener(trigger) {
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can install the PWA
    delete localStorage['pwaInstallPath'];
    trigger();
    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`'beforeinstallprompt' event was fired.`);
  });
}

window.addEventListener('appinstalled', () => {
  localStorage['pwaInstallPath'] = window.location.pathname.replace('/', '');
});

export default ({ currentUrl }) => {
  console.log('currentUrl in pwa', currentUrl);

  const url = new URL(currentUrl);
  const hostname = url.hostname;

  let dataAfterSlash;

  if (hostname === 'localbytes.co') {
    // Extract part after the hostname
    const parts = url.pathname.split('/');
    dataAfterSlash = parts[1];
  } else {
    // Handle other hostname cases
    dataAfterSlash = hostname;
  }

  console.log(dataAfterSlash);
  // React.useEffect(() => {
  //   setPwaModalVisible(true)
  // }, [])

  // const marketplace = localStorage.getItem("marketplace");
  // console.log("marketplace popup",marketplace);

  let modalVisibleFormLocalStorage =
    localStorage.getItem('dontShowInstallPwa') || true;
  const [pwaModalVisible, setPwaModalVisible] = useState(
    modalVisibleFormLocalStorage
  );

  const installPwa = async () => {
    deferredPrompt.prompt();
    setPwaModalVisible(false);

    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      deferredPrompt = null;
      setRerender((i) => i + 1);
      localStorage['pwaInstallPath'] = window.location.pathname.replace(
        '/',
        ''
      );
    }
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
  };

  const dontShowInstallPwa = () => {
    localStorage['dontShowInstallPwa'] = true;
    setRerender((i) => i + 1);
    setPwaModalVisible(false);
  };

  const [renderIdx, setRerender] = useState(0);

  registerEventListener(() => setRerender(renderIdx + 1));

  if (localStorage['dontShowInstallPwa']) {
    return null;
  }

  if (!deferredPrompt?.prompt) {
    return null;
  }

  return (
    <Modal visible={pwaModalVisible} footer={null} centered closable={false}>
      <PwaNotificationWrapper>
        <div className="pwa-modal-content">
          <div className="pwa-restaurant-info">
            {dataAfterSlash === 'lacanadaeats.com' ? (
              <p>La Cañada Eats</p>
            ) : dataAfterSlash === 'the607eatslocal' ? (
              <p>The 607 Eats Local</p>
            ) : dataAfterSlash === 'local.pizzabox.ai' ? (
              <p>Pizzabox</p>
            ) : dataAfterSlash === 'coop.pizzabox.ai' ? (
              <p>Pizzabox</p>
            ) : (
              <p>The 607 Eats Local</p>
            )}
          </div>
        </div>
        {dataAfterSlash === 'lacanadaeats.com' ? (
          <>
            <h4>
              <IntlMessages id="pwaNotification.caption.firstlacanda" />
            </h4>
            <h4>
              <IntlMessages id="pwaNotification.caption.seclacanada" />
            </h4>
          </>
        ) : (
          <>
            <h4>
              <IntlMessages id="pwaNotification.caption.first" />
            </h4>
            <h4>
              <IntlMessages id="pwaNotification.caption.sec" />
            </h4>
          </>
        )}

        <br />
        <Button className="pwa-close-btn" onClick={dontShowInstallPwa}>
          <IntlMessages id="pwaNotification.dontShow" />
        </Button>
        <Button className="pwa-install-btn" onClick={installPwa}>
          <IntlMessages id="pwaNotification.install" />
        </Button>
      </PwaNotificationWrapper>
    </Modal>
  );
};
