import antdRu from 'antd/lib/locale-provider/ru_RU';
import enMessages from '../locales/ru_RU.json';

const RuLang = {
  messages: {
    ...enMessages,
  },
  antd: antdRu,
  locale: 'ru-RU',
};
export default RuLang;
