import { all, takeEvery, put, call } from 'redux-saga/effects';
import siteConfig from '@iso/config/site.config';
import checklistActions from './actions';

export const onChecklistRequest = async (brandId) =>
  await fetch(`${siteConfig.apiUrl}/checkLists/${brandId}`)
    .then((response) => response.json())
    .then((json) => json);

function* fetchChecklistDataEffect({ brandId }) {
  try {
    let checklist = yield call(onChecklistRequest, brandId);
    yield put(checklistActions.fetchChecklistDataSuccess(checklist.CheckList));
  } catch (error) {
    yield put(checklistActions.fetchChecklistDataFailure(error));
  }
}

export default function* checklistSaga() {
  yield all([
    takeEvery(
      checklistActions.FETCH_CHECKLIST_DATA_START,
      fetchChecklistDataEffect
    ),
  ]);
}
