import clone from 'clone';
import actions from './actions';

const initState = {
  initialPartners: false,
  initialPartnerIntegrations: false,
  initialRestaurant: false,
  partners: [],
  aggregators: [],
  courier_services: [],
  internet_acquirings: [],
  pos_systems: [],
  partnerIntegrations: [],
  currentPartner: {},
  restaurant: {},
  error: null,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.REQUEST_POSTS_PARTNERS_SUCCESS: {
      const partners = action.payload.map(function (el) {
        return { ...el, key: el.id };
      });
      return {
        ...state,
        initialPartners: true,
        partners: partners,
      };
    }
    case actions.REQUEST_POSTS_PARTNERS_FAILURE:
      return {
        ...state,
        initialPartners: false,
        error: action.payload,
      };
    case actions.DELETE_PARTNER_SUCCESS: {
      const partnerId = action.payload.userId;
      const isDelete = action.payload.isDelete;
      const partners = state.partners.slice();
      const pIdx = partners.findIndex((b) => b.id === parseInt(partnerId));

      if (pIdx !== -1 && isDelete) {
        delete partners[pIdx];
      }
      return {
        ...state,
        initialPartners: true,
        partners: partners,
      };
    }
    case actions.DELETE_PARTNER_FAILURE:
      return {
        ...state,
        initialPartners: false,
        error: action.payload,
      };

    case actions.REQUEST_POSTS_PARTNER_INTEGRATIONS:
      return state;
    case actions.RECEIVE_POSTS_PARTNER_INTEGRATIONS: {
      const partnerIntegrations = action.integrations.integrations.map(
        function (el) {
          return { ...el, key: el.id };
        }
      );

      const aggregators = action.integrations.aggregators.map(function (el) {
        const indx = partnerIntegrations?.findIndex(
          (integration) => integration.aggregator_id === el.id
        );
        let status = false;
        let inWork = false;
        let integration_id = 0;
        if (indx !== -1 && indx !== undefined) {
          inWork = true;
          status = partnerIntegrations[indx].status;
          integration_id = partnerIntegrations[indx].id;
        }
        return {
          ...el,
          key: el.id,
          status: status,
          inWork: inWork,
          integration_id: integration_id,
        };
      });
      const courier_services = action.integrations.courier_services.map(
        function (el) {
          const indx = partnerIntegrations?.findIndex(
            (integration) => integration.aggregator_id === el.id
          );
          let status = false;
          let inWork = false;
          let integration_id = 0;
          if (indx !== -1 && indx !== undefined) {
            inWork = true;
            status = partnerIntegrations[indx].status;
            integration_id = partnerIntegrations[indx].id;
          }
          return {
            ...el,
            key: el.id,
            status: status,
            inWork: inWork,
            integration_id: integration_id,
          };
        }
      );
      const internet_acquirings = action.integrations.internet_acquirings.map(
        function (el) {
          const indx = partnerIntegrations?.findIndex(
            (integration) => integration.aggregator_id === el.id
          );
          let status = false;
          let inWork = false;
          let integration_id = 0;
          if (indx !== -1 && indx !== undefined) {
            inWork = true;
            status = partnerIntegrations[indx].status;
            integration_id = partnerIntegrations[indx].id;
          }
          return {
            ...el,
            key: el.id,
            status: status,
            inWork: inWork,
            integration_id: integration_id,
          };
        }
      );
      const pos_systems = action.integrations.pos_systems.map(function (el) {
        const indx = partnerIntegrations?.findIndex(
          (integration) => integration.aggregator_id === el.id
        );
        let status = false;
        let inWork = false;
        let integration_id = 0;
        if (indx !== -1 && indx !== undefined) {
          inWork = true;
          status = partnerIntegrations[indx].status;
          integration_id = partnerIntegrations[indx].id;
        }
        return {
          ...el,
          key: el.id,
          status: status,
          inWork: inWork,
          integration_id: integration_id,
        };
      });

      return {
        ...state,
        initialPartnerIntegrations: true,
        partnerIntegrations: partnerIntegrations,
        aggregators: aggregators,
        courier_services: courier_services,
        internet_acquirings: internet_acquirings,
        pos_systems: pos_systems,
      };
    }
    case actions.UPDATE_PARTNER_INTEGRATION: {
      const integration = action.integration;
      const aggregators = state.aggregators.map(function (el) {
        let status = el.status;
        let inWork = el.inWork;
        let integration_id = el.integration_id;
        if (parseInt(integration.aggregator_id) === parseInt(el.id)) {
          inWork = integration.deleted !== true;
          status = integration.status;
          integration_id = integration.id;
        }
        return {
          ...el,
          key: el.id,
          status: status,
          inWork: inWork,
          integration_id: integration_id,
        };
      });
      return {
        ...state,
        aggregators: aggregators,
      };
    }
    case actions.REQUEST_POSTS_RESTAURANT:
      return state;
    case actions.RECEIVE_POSTS_RESTAURANT: {
      return {
        ...state,
        initialRestaurant: true,
        restaurant: clone(action.restaurant),
      };
    }
    case actions.UPDATE_PARTNER: {
      return {
        ...state,
        initialPartners: false,
      };
    }
    case actions.SELECT_CURRENT_PARTNER: {
      const questionnaires = state.questionnaires;
      const index = questionnaires
        .map((questionnaire) => questionnaire.id)
        .indexOf(action.id);
      if (index !== -1) {
        const currentPartner = questionnaires[index];
        return {
          ...state,
          currentPartner,
        };
      } else
        return {
          ...state,
        };
    }
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
