import siteConfig from '@iso/config/site.config';

const actions = {
  REQUEST_POSTS_QUESTIONNAIRE: 'REQUEST_POSTS_QUESTIONNAIRE',
  RECEIVE_POSTS_QUESTIONNAIRE: 'RECEIVE_POSTS_QUESTIONNAIRE',
  UPDATE_QUESTIONNAIRE: 'UPDATE_QUESTIONNAIRE',
  UPDATE_QUESTIONNAIRE_SAGA: 'UPDATE_QUESTIONNAIRE_SAGA',
  SELECT_CURRENT_QUESTIONNAIRE: 'SELECT_CURRENT_QUESTIONNAIRE',
  TOGGLE_VIEW: 'QUESTIONNAIRE_TOGGLE_VIEW',
  UPDATE_EDIT_QUESTIONNAIRE: 'QUESTIONNAIRE_UPDATE_EDIT_QUESTIONNAIRE',

  FETCH_USER_QUESTIONNAIRE_START: 'FETCH_QUESTIONNAIRE_START',
  FETCH_USER_QUESTIONNAIRE_SUCCESS: 'FETCH_USER_QUESTIONNAIRE_SUCCESS',
  FETCH_USER_QUESTIONNAIRE_FAILURE: 'FETCH_USER_QUESTIONNAIRE_FAILURE',
  SUBMIT_USER_QUESTIONNAIRE_START: 'SUBMIT_QUESTIONNAIRE_START',
  SUBMIT_USER_QUESTIONNAIRE_SUCCESS: 'SUBMIT_USER_QUESTIONNAIRE_SUCCESS',
  SUBMIT_USER_QUESTIONNAIRE_FAILURE: 'SUBMIT_USER_QUESTIONNAIRE_FAILURE',
  /*
   * to be removed*/
  UPDATE_USER_QUESTIONNAIRE: 'UPDATE_USER_QUESTIONNAIRE',

  FETCH_USER_BRANDS_START: 'FETCH_USER_BRANDS_START',
  FETCH_USER_BRANDS_SUCCESS: 'FETCH_USER_BRANDS_SUCCESS',
  FETCH_USER_BRANDS_FAILURE: 'FETCH_USER_BRANDS_FAILURE',

  ADD_USER_BRAND_START: 'ADD_USER_BRAND_START',
  ADD_USER_BRAND_SUCCESS: 'ADD_USER_BRAND_SUCCESS',
  ADD_USER_BRAND_FAILURE: 'ADD_USER_BRAND_FAILURE',

  REMOVE_USER_BRAND_START: 'REMOVE_USER_BRAND_START',
  REMOVE_USER_BRAND_SUCCESS: 'REMOVE_USER_BRAND_SUCCESS',
  REMOVE_USER_BRAND_FAILURE: 'REMOVE_USER_BRAND_FAILURE',

  FETCH_USER_REQUISITES_START: 'FETCH_USER_REQUISITES_START',
  FETCH_USER_REQUISITES_SUCCESS: 'FETCH_USER_REQUISITES_SUCCESS',
  FETCH_USER_REQUISITES_FAILURE: 'FETCH_USER_REQUISITES_FAILURE',
  SUBMIT_USER_REQUISITES_START: 'SUBMIT_USER_REQUISITES_START',
  SUBMIT_USER_REQUISITES_SUCCESS: 'SUBMIT_USER_REQUISITES_SUCCESS',
  SUBMIT_USER_REQUISITES_FAILURE: 'SUBMIT_USER_REQUISITES_FAILURE',

  /* to be removed*/
  UPDATE_USER_REQUISITES: 'UPDATE_USER_REQUISITES',

  LOGOUT: 'LOGOUT',

  getQuestionnaires: () => {
    return (dispatch) => {
      dispatch({
        type: actions.REQUEST_POSTS_QUESTIONNAIRE,
      });
      return fetch(`${siteConfig.apiUrl}/forms`)
        .then((response) => response.json())
        .then((json) => {
          dispatch({
            type: actions.UPDATE_QUESTIONNAIRE,
            questionnaires: json['GuestForms'],
          });
        });
    };
  },

  fetchUserQuestionnaireStart: (userId) => ({
    type: actions.FETCH_USER_QUESTIONNAIRE_START,
    payload: userId,
  }),
  fetchUserQuestionnaireSuccess: (userQuestionnaire) => ({
    type: actions.FETCH_USER_QUESTIONNAIRE_SUCCESS,
    payload: userQuestionnaire,
  }),
  fetchUserQuestionnaireFailure: (error) => ({
    type: actions.FETCH_USER_QUESTIONNAIRE_FAILURE,
    payload: error,
  }),

  submitUserQuestionnaireStart: (userId, userQuestionnaire, files) => ({
    type: actions.SUBMIT_USER_QUESTIONNAIRE_START,
    payload: { userId, userQuestionnaire, files },
  }),
  submitUserQuestionnaireSuccess: (userQuestionnaire) => ({
    type: actions.SUBMIT_USER_QUESTIONNAIRE_SUCCESS,
    payload: userQuestionnaire,
  }),
  submitUserQuestionnaireFailure: (error) => ({
    type: actions.SUBMIT_USER_QUESTIONNAIRE_FAILURE,
    payload: error,
  }),

  fetchUserBrandsStart: (userId) => ({
    type: actions.FETCH_USER_BRANDS_START,
    payload: userId,
  }),
  fetchUserBrandsSuccess: (userBrands) => ({
    type: actions.FETCH_USER_BRANDS_SUCCESS,
    payload: { userBrands },
  }),
  fetchUserBrandsFailure: (error) => ({
    type: actions.FETCH_USER_BRANDS_FAILURE,
    payload: error,
  }),

  addUserBrandsStart: (userId, brandId) => ({
    type: actions.ADD_USER_BRAND_START,
    payload: { userId, brandId },
  }),
  addUserBrandsSuccess: ({ userId, brandId }) => ({
    type: actions.ADD_USER_BRAND_SUCCESS,
    payload: { userId, brandId },
  }),
  addUserBrandsFailure: (error) => ({
    type: actions.ADD_USER_BRAND_FAILURE,
    payload: error,
  }),

  removeUserBrandsStart: (userId, brandId) => ({
    type: actions.REMOVE_USER_BRAND_START,
    payload: { userId, brandId },
  }),
  removeUserBrandsSuccess: ({ userId, brandId }) => ({
    type: actions.REMOVE_USER_BRAND_SUCCESS,
    payload: { userId, brandId },
  }),
  removeUserBrandsFailure: (error) => ({
    type: actions.REMOVE_USER_BRAND_FAILURE,
    payload: error,
  }),

  fetchUserRequisitesStart: (userId) => ({
    type: actions.FETCH_USER_REQUISITES_START,
    payload: userId,
  }),
  fetchUserRequisitesSuccess: (userRequisites) => ({
    type: actions.FETCH_USER_REQUISITES_SUCCESS,
    payload: { userRequisites },
  }),
  fetchUserRequisitesFailure: (error) => ({
    type: actions.FETCH_USER_REQUISITES_FAILURE,
    payload: error,
  }),

  submitUserRequisitesStart: (userId, userRequisites) => ({
    type: actions.SUBMIT_USER_REQUISITES_START,
    payload: { userId, userRequisites },
  }),
  submitUserRequisitesSuccess: (userRequisites) => ({
    type: actions.SUBMIT_USER_REQUISITES_SUCCESS,
    payload: { userRequisites },
  }),
  submitUserRequisitesFailure: (error) => ({
    type: actions.SUBMIT_USER_REQUISITES_FAILURE,
    payload: error,
  }),

  getUserRequisites: (userId) => {
    return (dispatch) => {
      //      dispatch({
      //        type: actions.FETCH_USER_REQUISITES_START,
      //      });
      return fetch(`${siteConfig.apiUrl}/userRequisites/${userId}`)
        .then((response) => response.json())
        .then((json) => {
          dispatch({
            type: actions.FETCH_USER_REQUISITES_SUCCESS,
            payload: { userRequisites: json },
          });
        });
    };
  },

  deleteQuestionnaire: (selected) => {
    return (dispatch, getState) => {
      const questionnaires = getState().Questionnaires.questionnaires;
      const newQuestionnaires = [];
      questionnaires.forEach((questionnaire) => {
        const selectedIndex = selected.indexOf(questionnaire.id);
        if (selectedIndex === -1) {
          newQuestionnaires.push(questionnaire);
        }
      });
      dispatch({
        type: actions.UPDATE_QUESTIONNAIRE_SAGA,
        questionnaires: newQuestionnaires,
      });
    };
  },
  updateQuestionnaire: (questionnaire) => {
    return (dispatch, getState) => {
      const questionnaires = getState().Questionnaires.questionnaires;
      const index = questionnaires
        .map((ques) => ques.id)
        .indexOf(questionnaire.id);
      if (index === -1) {
        questionnaires.push(questionnaire);
      } else {
        questionnaires[index] = questionnaire;
      }
      dispatch({
        type: actions.UPDATE_QUESTIONNAIRE,
        questionnaires,
        questionnaire,
      });
    };
  },
  updateUserQuestionnaire: (userQuestionnaire) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_USER_QUESTIONNAIRE,
        userQuestionnaire,
      });
    };
  },
  selectCurrentQuestionnaire: (id) => ({
    type: actions.SELECT_CURRENT_QUESTIONNAIRE,
    id: parseInt(id),
  }),
  toggleView: (view) => ({ type: actions.TOGGLE_VIEW, view }),
  editQuestionnaire: (questionnaire) => {
    return (dispatch) => {
      return fetch(`${siteConfig.apiUrl}/updateUserForm`, {
        crossDomain: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(questionnaire),
      })
        .then((response) => response.json())
        .then(() => {
          dispatch({
            type: actions.UPDATE_EDIT_QUESTIONNAIRE,
            questionnaire,
          });
        });
    };
  },

  async uploadQuestionnaire(body) {
    // const response = await fetch('http://127.0.0.1:35000/guest/form', {
    const response = await fetch(`${siteConfig.apiUrl}/guest/form`, {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(body),
    });
    return await response.json();
  },

  async uploadQuestionnaireFiles(file) {
    // const response = await fetch('http://127.0.0.1:35000/upload', {
    const response = await fetch(`${siteConfig.apiUrl}/upload`, {
      crossDomain: true,
      method: 'POST',
      mode: 'cors',
      body: file,
    });
    return await response.json();
  },

  async downloadFile(url) {
    const response = await fetch(url, {
      method: 'GET',
      // responseType: 'blob',
      // mode: 'no-cors'
    });
    return await response.blob();
  },

  async getUserQuestionnaireAsync(userId) {
    const response = await fetch(`${siteConfig.apiUrl}/userForm/${userId}`, {
      method: 'GET',
      // responseType: 'blob',
      // mode: 'no-cors'
    });
    return await response.json();
  },

  async uploadRequisites(body) {
    // const response = await fetch('http://127.0.0.1:35000/guest/form', {
    const response = await fetch(`${siteConfig.apiUrl}/guest/requisites/`, {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(body),
    });
    return await response.json();
  },
};
export default actions;
