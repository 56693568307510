import checklistActions from './actions';

const INITIAL_DATA = {
  CheckList: [],
  loading: true,
  error: null,
};

export default function checklistReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case checklistActions.FETCH_CHECKLIST_DATA_SUCCESS:
      return {
        ...state,
        CheckList: action.payload,
        loading: false,
        error: null,
      };

    case checklistActions.FETCH_CHECKLIST_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
