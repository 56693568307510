const localDataName = 'mateOrders';
const orderStatusOptions = ['Pending', 'Shipped', 'Delivered'];
const newTestOrderBrand = [1, 3, 5, 10, 12];
const orderStatuses = new Map(
  Object.entries({
    created: 'Новый заказ',
    adopted: 'Новый заказ',
    accepted: 'Новый заказ',
    cooking: 'Заказ готовится',
    ready: 'Заказ готов',
    on_the_way: 'Заказ в пути',
    delivered: 'Доставлен',
    customer_cancelled: 'Отменён клиентом',
    restaurant_cancelled: 'Отменён рестораном',
  })
);
const newTestOrder = {
  originalOrderId: '32-3410-0031',
  preOrder: true,
  createdAt: '2021-10-04T12:19:00+0300',
  customer: {
    name: 'ТЕСТОВЫЙ ЗАКАЗ',
    phone: '+78001233212',
    email: 'user@example.com',
  },
  payment: {
    type: 'cash',
    requiredMoneyChange: '1000',
  },
  expeditionType: 'delivery',
  delivery: {
    expectedTime: '2021-5-27T18:40:00+0300',
    address: {
      subway: 'Беляево',
      region: 'Москва',
      city: {
        name: 'Москва',
        code: '777',
      },
      street: {
        name: 'Полярная',
        code: '15',
      },
      houseNumber: '27',
      flatNumber: '332',
      entrance: '4',
      intercom: '#0000#',
      floor: '10',
      coordinates: {
        latitude: '55.823479',
        longitude: '37.6122628',
      },
    },
  },
  pickup: {
    expectedTime: '2021-5-27T18:50:00+0300',
    taker: 'courier',
  },
  products: [
    {
      id: '1',
      name: 'Традиционный салат Чайна-таун',
      price: 150,
      quantity: 2,
      promotionId: '',
    },
  ],
  promotions: [
    {
      id: '1',
      name: 'Бесплатный напиток',
    },
    {
      id: '2',
      name: 'Скидка 20%',
    },
  ],
  comment: 'ТЕСТОВЫЙ ЗАКАЗ',
  price: {
    total: 0,
    deliveryFee: 0,
    discount: 0,
  },
  personsQuantity: 1,
  callCenter: {
    phone: '+79257879602',
  },
  courier: {
    name: 'ТЕСТОВЫЙ ЗАКАЗ',
    phone: '+78005553535',
  },
};

export {
  localDataName,
  orderStatusOptions,
  newTestOrder,
  newTestOrderBrand,
  orderStatuses,
};
