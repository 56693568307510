import menuActions from './actions';
import Notification from '@iso/components/Notification';

const INITIAL_DATA = {
  data: null,
  categories: [],
  loading: true,
  loadingCategories: true,
  savingProduct: false,
  wlUrl: null,
  posData: null,
  error: null,
};
export default function menuReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case menuActions.FETCH_MENU_DATA_START:
      return {
        ...state,
        data: null,
        loading: true,
      };
    case menuActions.FETCH_MENU_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case menuActions.FETCH_MENU_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case menuActions.FETCH_CATEGORIES_DATA_SUCCESS:
      return {
        ...state,
        loadingCategories: false,
        categories: action.payload,
        error: null,
      };
    case menuActions.FETCH_CATEGORIES_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case menuActions.ADD_PRODUCT:
    case menuActions.EDIT_PRODUCT: {
      return {
        ...state,
        savingProduct: true,
      };
    }
    case menuActions.ADD_PRODUCT_SUCCESS: {
      const product = action.payload;
      const products = state.data.menuItems.products.slice();
      const pIdx = products.findIndex((p) => p.id === product.id);

      if (pIdx === -1) {
        products.push(product);
      } else {
        products[pIdx] = product;
      }

      return {
        ...state,
        savingProduct: false,
        data: {
          ...state.data,
          menuItems: {
            ...state.data.menuItems,
            products: products,
          },
        },
      };
    }
    case menuActions.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        savingProduct: false,
        error: action.payload,
      };

    case menuActions.CREATE_CATEGORY_SUCCESS: {
      const newCat = action.payload;
      const categories = state.data.menuItems.categories;
      categories.push(newCat);
      console.log({ newCat, categories });

      return {
        ...state,
        data: {
          ...state.data,
          menuItems: {
            ...state.data.menuItems,
            categories: categories,
          },
        },
      };
    }
    case menuActions.ADD_CATEGORY_SUCCESS:
    case menuActions.DELETE_CATEGORY_SUCCESS:
      const category = action.payload;
      const categories = state.data.menuItems.categories.filter(
        (p) => parseInt(p.id) !== category
      );

      // const newСategories = categories.filter((p) => parseInt(p.id) !== category);
      console.log({ category, categories });

      return {
        ...state,
        data: {
          ...state.data,
          menuItems: {
            ...state.data.menuItems,
            categories: categories,
          },
        },
      };
    case menuActions.EDIT_CATEGORY_SUCCESS: {
      const category = action.payload;
      let categories = state.data.menuItems.categories.slice();
      let index = categories.findIndex((p) => p.id === category.id);

      if (index === -1) {
        categories.push(category);
      } else {
        categories[index] = category;
      }
      categories.sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      return {
        ...state,
        data: {
          ...state.data,
          menuItems: {
            ...state.data.menuItems,
            categories: categories,
          },
        },
      };
    }
    case menuActions.ADD_CATEGORY_FAILURE:
    case menuActions.CREATE_CATEGORY_FAILURE:
    case menuActions.EDIT_CATEGORY_FAILURE:
    case menuActions.DELETE_CATEGORY_FAILURE:
    case menuActions.FETCH_WLURL_FAILURE:
    case menuActions.FETCH_POS_EXPORT_STATUS_FAILURE:
    case menuActions.FETCH_POS_EXPORT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case menuActions.EDIT_PRODUCT_SUCCESS: {
      const product = action.payload;
      const products = state.data.menuItems.products.slice();
      const pIdx = products.findIndex((p) => p.id === product.id);

      if (pIdx === -1) {
        products.push(product);
      } else {
        products[pIdx] = product;
      }

      return {
        ...state,
        savingProduct: false,
        data: {
          ...state.data,
          menuItems: {
            ...state.data.menuItems,
            products: products,
          },
        },
      };
    }

    case menuActions.EDIT_PRODUCT_FAILURE:
      return {
        ...state,
        savingProduct: false,
        loading: false,
        error: action.payload,
      };

    case menuActions.DELETE_PRODUCT_SUCCESS: {
      const productId = action.payload;
      const products = state.data.menuItems.products.slice();
      const pIdx = products.findIndex((p) => p.id === productId);

      if (pIdx !== -1) {
        products.splice(pIdx, 1);
      }

      return {
        ...state,
        data: {
          ...state.data,
          menuItems: {
            ...state.data.menuItems,
            products: products,
          },
        },
      };
    }

    case menuActions.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case menuActions.FETCH_WLURL_SUCCESS: {
      return {
        ...state,
        wlUrl: action.payload,
      };
    }
    case menuActions.FETCH_POS_EXPORT_STATUS_SUCCESS: {
      return {
        ...state,
        posData: action.payload,
      };
    }
    case menuActions.FETCH_POS_EXPORT_SUCCESS: {
      return {
        posData: action.payload,
      };
    }
    case menuActions.FETCH_CREATE_WL_SUCCESS: {
      Notification(
        'success',
        `Сайт создан`,
        'Для бренда был сгенерирован собственный сайт доставки!'
      );
      return {
        ...state,
      };
    }
    case menuActions.FETCH_CREATE_WL_FAILURE: {
      Notification(
        'warning',
        `Не удалось создать сайт :(`,
        'Для бренда не был сгенерирован собственный сайт доставки, пожалуйста, обратитесь в поддержку'
      );
      return {
        ...state,
        error: action.payload,
      };
    }
    case menuActions.SET_QR_MENU_PROPERTY_SUCCESS: {
      Notification('success', `QR-меню сохранено`);
      return {
        ...state,
        data: {
          ...state.data,
          isQrMenu: action.payload.status.status,
        },
      };
    }
    case menuActions.SET_QR_MENU_PROPERTY_FAILURE: {
      Notification(
        'warning',
        `Не удалось сохранить свойстов :(`,
        'Не было сохранено свойство QR-меню'
      );
      return {
        ...state,
        error: action.payload,
      };
    }

    default:
      return state;
  }
}
