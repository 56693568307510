const reportGeneratorActions = {
  FETCH_REPORT_START: 'FETCH_REPORT_START',
  FETCH_REPORT_SUCCESS: 'FETCH_REPORT_SUCCESS',
  FETCH_REPORT_FAILURE: 'FETCH_REPORT_FAILURE',
  FETCH_FIN_REPORT_START: 'FETCH_FIN_REPORT_START',
  FETCH_FIN_REPORT_FILTERS_START: 'FETCH_FIN_REPORT_FILTERS_START',
  FETCH_FIN_REPORT_SUCCESS: 'FETCH_FIN_REPORT_SUCCESS',
  FETCH_FIN_REPORT_FAILURE: 'FETCH_FIN_REPORT_FAILURE',

  fetchReportStart: (request) => ({
    type: reportGeneratorActions.FETCH_REPORT_START,
    payload: request,
  }),
  fetchReportSuccess: (report) => ({
    type: reportGeneratorActions.FETCH_REPORT_SUCCESS,
    payload: report,
  }),
  fetchReportFailure: (error) => ({
    type: reportGeneratorActions.FETCH_REPORT_FAILURE,
    payload: error,
  }),

  fetchFinReportStart: (restaurantId) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_START,
    payload: restaurantId,
  }),
  fetchFinReportFiltersStart: (filters) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_FILTERS_START,
    payload: filters,
  }),
  fetchFinReportSuccess: (report) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_SUCCESS,
    payload: report,
  }),
  fetchFinReportFailure: (error) => ({
    type: reportGeneratorActions.FETCH_FIN_REPORT_FAILURE,
    payload: error,
  }),
};

export default reportGeneratorActions;
