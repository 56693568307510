const localEatsActions = {
  FETCH_MARKETPLACE_DATA_START: 'FETCH_MARKETPLACE_DATA_START',
  FETCH_MARKETPLACE_DATA_SUCCESS: 'FETCH_MARKETPLACE_DATA_SUCCESS',
  FETCH_MARKETPLACE_DATA_FAILURE: 'FETCH_MARKETPLACE_DATA_FAILURE',

  FETCH_MARKETPLACE_BRANDS_START: 'FETCH_MARKETPLACE_BRANDS_START',
  FETCH_MARKETPLACE_BRANDS_SUCCESS: 'FETCH_MARKETPLACE_BRANDS_SUCCESS',
  FETCH_MARKETPLACE_BRANDS_FAILURE: 'FETCH_MARKETPLACE_BRANDS_FAILURE',

  FETCH_MARKETPLACE_FILTER_BRANDS_START:
    'FETCH_MARKETPLACE_FILTER_BRANDS_START',
  FETCH_MARKETPLACE_FILTER_BRANDS_SUCCESS:
    'FETCH_MARKETPLACE_FILTER_BRANDS_SUCCESS',
  FETCH_MARKETPLACE_FILTER_BRANDS_FAILURE:
    'FETCH_MARKETPLACE_FILTER_BRANDS_FAILURE',

  FETCH_MARKETPLACE_OFFERS_START: 'FETCH_MARKETPLACE_OFFERS_START',
  FETCH_MARKETPLACE_OFFERS_SUCCESS: 'FETCH_MARKETPLACE_OFFERS_SUCCESS',
  FETCH_MARKETPLACE_OFFERS_FAILURE: 'FETCH_MARKETPLACE_OFFERS_FAILURE',

  FETCH_MENU_FILTER_START: 'FETCH_MENU_FILTER_START',
  FETCH_MENU_FILTER_SUCCESS: 'FETCH_MENU_FILTER_SUCCESS',
  FETCH_MENU_FILTER_FAILURE: 'FETCH_MENU_FILTER_FAILURE',

  fetchMenuFilterStart: (marketplaceCode) => ({
    type: localEatsActions.FETCH_MENU_FILTER_START,
    payload: marketplaceCode,
  }),
  fetchMenuFilterSuccess: (filters) => ({
    type: localEatsActions.FETCH_MENU_FILTER_SUCCESS,
    payload: filters,
  }),
  fetchMenuFilterFailure: (error) => ({
    type: localEatsActions.FETCH_MENU_FILTER_FAILURE,
    payload: error,
  }),

  fetchMarketplaceDataStart: (marketplaceCode) => ({
    type: localEatsActions.FETCH_MARKETPLACE_DATA_START,
    payload: marketplaceCode,
  }),
  fetchMarketplaceDataSuccess: (data) => ({
    type: localEatsActions.FETCH_MARKETPLACE_DATA_SUCCESS,
    payload: data,
  }),
  fetchMarketplaceDataFailure: (error) => ({
    type: localEatsActions.FETCH_MARKETPLACE_DATA_FAILURE,
    payload: error,
  }),

  fetchMarketplaceBrandsStart: (marketplaceCode) => ({
    type: localEatsActions.FETCH_MARKETPLACE_BRANDS_START,
    payload: marketplaceCode,
  }),
  fetchMarketplaceBrandsSuccess: (data) => ({
    type: localEatsActions.FETCH_MARKETPLACE_BRANDS_SUCCESS,
    payload: data,
  }),
  fetchMarketplaceBrandsFailure: (error) => ({
    type: localEatsActions.FETCH_MARKETPLACE_BRANDS_FAILURE,
    payload: error,
  }),

  fetchMarketplaceFilterBrandsStart: (marketplaceCode, filters) => ({
    type: localEatsActions.FETCH_MARKETPLACE_FILTER_BRANDS_START,
    payload: marketplaceCode,
    filters,
  }),
  fetchMarketplaceFilterBrandsSuccess: (data) => ({
    type: localEatsActions.FETCH_MARKETPLACE_FILTER_BRANDS_SUCCESS,
    payload: data,
  }),
  fetchMarketplaceFilterBrandsFailure: (error) => ({
    type: localEatsActions.FETCH_MARKETPLACE_FILTER_BRANDS_FAILURE,
    payload: error,
  }),

  fetchMarketplaceOffersStart: (marketplaceCode) => ({
    type: localEatsActions.FETCH_MARKETPLACE_OFFERS_START,
    payload: marketplaceCode,
  }),
  fetchMarketplaceOffersSuccess: (data) => ({
    type: localEatsActions.FETCH_MARKETPLACE_OFFERS_SUCCESS,
    payload: data,
  }),
  fetchMarketplaceOffersFailure: (error) => ({
    type: localEatsActions.FETCH_MARKETPLACE_OFFERS_FAILURE,
    payload: error,
  }),
};

export default localEatsActions;
