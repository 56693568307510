const stopListsActions = {
  FETCH_STOP_LISTS_DATA_START: 'FETCH_STOP_LISTS_DATA_START',
  FETCH_STOP_LISTS_DATA_SUCCESS: 'FETCH_STOP_LISTS_DATA_SUCCESS',
  FETCH_STOP_LISTS_DATA_FAILURE: 'FETCH_STOP_LISTS_DATA_FAILURE',
  SET_STOP_LISTS_DATA: 'SET_STOP_LISTS_DATA',
  SET_STOP_LISTS_ITEM: 'SET_STOP_LISTS_ITEM',
  SET_STOP_LISTS_ITEM_SUCCESS: 'SET_STOP_LISTS_ITEM_SUCCESS',
  SET_STOP_LISTS_ITEM_FAILURE: 'SET_STOP_LISTS_ITEM_FAILURE',

  FETCH_STOP_LISTS_ITEM_RESTAURANTS: 'FETCH_STOP_LISTS_ITEM_RESTAURANTS',
  FETCH_STOP_LISTS_ITEM_RESTAURANTS_SUCCESS:
    'FETCH_STOP_LISTS_ITEM_RESTAURANTS_SUCCESS',
  FETCH_STOP_LISTS_ITEM_RESTAURANTS_FAILURE:
    'FETCH_STOP_LISTS_ITEM_RESTAURANTS_FAILURE',

  SET_STOP_LIST_ITEM_RESTAURANT: 'SET_STOP_LIST_ITEM_RESTAURANT',
  SET_STOP_LIST_ITEM_RESTAURANT_SUCCESS:
    'SET_STOP_LIST_ITEM_RESTAURANT_SUCCESS',
  SET_STOP_LIST_ITEM_RESTAURANT_FAILURE:
    'SET_STOP_LIST_ITEM_RESTAURANT_FAILURE',

  fetchStopListsDataStart: (brandId) => ({
    type: stopListsActions.FETCH_STOP_LISTS_DATA_START,
    payload: brandId,
  }),
  fetchStopListsDataSuccess: (StopLists) => ({
    type: stopListsActions.FETCH_STOP_LISTS_DATA_SUCCESS,
    payload: StopLists,
  }),
  fetchStopListsDataFailure: (error) => ({
    type: stopListsActions.FETCH_STOP_LISTS_DATA_FAILURE,
    payload: error,
  }),
  setStopListsItem: (id, status, restaurantId) => ({
    type: stopListsActions.SET_STOP_LISTS_ITEM,
    payload: { id, status, restaurantId },
  }),
  setStopListsItemSuccess: (id, status, brandId) => ({
    type: stopListsActions.SET_STOP_LISTS_ITEM_SUCCESS,
    payload: { id, status, brandId },
  }),
  setStopListsItemFailure: (error) => ({
    type: stopListsActions.SET_STOP_LISTS_ITEM_FAILURE,
    payload: error,
  }),
  fetchStopListsItemRestaurants: (productId) => ({
    type: stopListsActions.FETCH_STOP_LISTS_ITEM_RESTAURANTS,
    payload: productId,
  }),
  fetchStopListsItemRestaurantsSuccess: (productId, restaurants) => ({
    type: stopListsActions.FETCH_STOP_LISTS_ITEM_RESTAURANTS_SUCCESS,
    payload: {
      productId,
      restaurants: restaurants.restaurants,
    },
  }),
  fetchStopListsItemRestaurantsFailure: (productId, error) => ({
    type: stopListsActions.FETCH_STOP_LISTS_ITEM_RESTAURANTS_FAILURE,
    payload: {
      productId,
      error,
    },
  }),

  setStopListItemRestaurant: (productId, restaurantId, status) => ({
    type: stopListsActions.SET_STOP_LIST_ITEM_RESTAURANT,
    payload: {
      productId,
      restaurantId,
      status,
    },
  }),
  setStopListItemRestaurantSuccess: (productId, restaurantId, status) => ({
    type: stopListsActions.SET_STOP_LIST_ITEM_RESTAURANT_SUCCESS,
    payload: {
      productId,
      restaurantId,
      status,
    },
  }),
  setStopListItemRestaurantFailure: (productId, restaurantId, error) => ({
    type: stopListsActions.SET_STOP_LIST_ITEM_RESTAURANT_FAILURE,
    payload: {
      productId,
      restaurantId,
      error,
    },
  }),
};

export default stopListsActions;
