import siteConfig from '@iso/config/site.config';

const actions = {
  REQUEST_POSTS_PARTNERS: 'REQUEST_POSTS_PARTNERS',
  REQUEST_POSTS_PARTNERS_SUCCESS: 'REQUEST_POSTS_PARTNERS_SUCCESS',
  REQUEST_POSTS_PARTNERS_FAILURE: 'REQUEST_POSTS_PARTNERS_FAILURE',

  DELETE_PARTNER: 'DELETE_PARTNER',
  DELETE_PARTNER_SUCCESS: 'DELETE_PARTNER_SUCCESS',
  DELETE_PARTNER_FAILURE: 'DELETE_PARTNER_FAILURE',

  UPDATE_PARTNER: 'UPDATE_PARTNER',
  SELECT_CURRENT_PARTNER: 'SELECT_CURRENT_PARTNER',
  LOGOUT: 'LOGOUT',
  UPDATE_PARTNER_INTEGRATION: 'UPDATE_PARTNER_INTEGRATION',
  REQUEST_POSTS_RESTAURANT: 'REQUEST_POSTS_RESTAURANT',
  RECEIVE_POSTS_RESTAURANT: 'RECEIVE_POSTS_RESTAURANT',
  REQUEST_POSTS_PARTNER_INTEGRATIONS: 'REQUEST_POSTS_PARTNER_INTEGRATIONS',
  RECEIVE_POSTS_PARTNER_INTEGRATIONS: 'RECEIVE_POSTS_PARTNER_INTEGRATIONS',

  GetAllPartners: () => ({
    type: actions.REQUEST_POSTS_PARTNERS,
  }),
  GetAllPartnersSuccess: (partners) => ({
    type: actions.REQUEST_POSTS_PARTNERS_SUCCESS,
    payload: partners,
  }),
  GetAllPartnersFailure: (error) => ({
    type: actions.REQUEST_POSTS_PARTNERS_FAILURE,
    payload: error,
  }),

  deletePartner: (userId) => ({
    type: actions.DELETE_PARTNER,
    payload: userId,
  }),
  deletePartnerSuccess: (isDelete, userId) => ({
    type: actions.DELETE_PARTNER_SUCCESS,
    payload: { isDelete, userId },
  }),
  deletePartnerFailure: (error) => ({
    type: actions.DELETE_PARTNER_FAILURE,
    payload: error,
  }),

  GetPartnerIntegrations: (userId) => {
    return (dispatch) => {
      dispatch({
        type: actions.REQUEST_POSTS_PARTNER_INTEGRATIONS,
      });
      return fetch(`${siteConfig.apiUrl}/partnerIntegrations/${userId}`)
        .then((response) => response.json())
        .then((json) => {
          dispatch({
            type: actions.RECEIVE_POSTS_PARTNER_INTEGRATIONS,
            integrations: json,
          });
        });
    };
  },
  savePartnerIntegration: (integration) => {
    return (dispatch) => {
      return fetch(`${siteConfig.apiUrl}/partnerIntegrations`, {
        crossDomain: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(integration),
      })
        .then((response) => response.json())
        .then((integration) => {
          dispatch({
            type: actions.UPDATE_PARTNER_INTEGRATION,
            integration,
          });
        });
    };
  },
  GetPartnerRestaurant: (userId) => {
    return (dispatch) => {
      dispatch({
        type: actions.REQUEST_POSTS_RESTAURANT,
      });
      return fetch(`${siteConfig.apiUrl}/userRestaurant/${userId}`)
        .then((response) => response.json())
        .then((json) => {
          dispatch({
            type: actions.RECEIVE_POSTS_RESTAURANT,
            restaurant: json,
          });
        });
    };
  },
  editPartner: (partner) => {
    console.log(partner);
    return (dispatch) => {
      return fetch(`${siteConfig.apiUrl}/partners`, {
        crossDomain: true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify(partner),
      })
        .then((response) => response.json())
        .then(() => {
          dispatch({
            type: actions.UPDATE_PARTNER,
            partner,
          });
        });
    };
  },
};
export default actions;
