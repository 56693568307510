import actions from './actions';

const initState = {
  idToken: null,
  UserRole: '',
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        UserRole: action.userRole,
      };
    case actions.REGISTRATION_SUCCESS:
      return {
        idToken: action.token,
        UserRole: action.userRole,
      };
    case actions.LOGOUT:
      return initState;
    case actions.LOGIN_USER_REQUEST:
      return {
        idToken: action.token,
      };
    default:
      return state;
  }
}
