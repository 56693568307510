import { all, takeEvery, put, call } from 'redux-saga/effects';
import { localDataName } from './config';
import actions from './actions';
import authActions from '@iso/redux/auth/actions';
import siteConfig from '@iso/config/site.config';

export const onActiveOrders = async () =>
  await fetch(`${siteConfig.apiUrl}/allorders`)
    .then((response) => response.json())
    .then((json) => {
      return json['orders'];
    });

export function* updateRestaurantOrdersSaga({ restaurantOrders, order }) {
  console.log('local storage restaurantOrders:', restaurantOrders);
  yield localStorage.setItem(localDataName, JSON.stringify(restaurantOrders));
  yield put({
    type: actions.UPDATE_RESTAURANT_ORDERS,
    restaurantOrders,
    order,
  });
}

function* fetchGetActiveOrders() {
  try {
    let activeOrders = yield call(onActiveOrders);
    yield put(actions.fetchGetActiveOrdersSuccess(activeOrders));
  } catch (error) {
    yield put(actions.fetchGetActiveOrdersFailure(error));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(
      actions.UPDATE_RESTAURANT_ORDERS_SAGA,
      updateRestaurantOrdersSaga
    ),
    yield takeEvery(
      actions.FETCH_GET_ACTIVE_ORDERS_START,
      fetchGetActiveOrders
    ),
  ]);
}

export function* logout() {
  yield takeEvery(authActions.LOGOUT, function* () {
    yield put({
      type: actions.LOGOUT,
    });
  });
}
