import clone from 'clone';
import actions from './actions';
import AudioNotification from '@iso/components/AudioNotification';
import Notification from '@iso/components/Notification';
import React from 'react';
import { orderStatuses } from './config';
import moment from 'moment';

const initState = {
  restaurantOrders: [],
  yaOrders: [],
  dcOrders: [],
  wlOrders: [],
  qrOrders: [],
  allRestaurantOrders: [],
  notificationOrder: {},
  orderCost: 0,
  initialWebSocketOrders: false,
  initialRestaurantOrders: false,
  initialRestaurantAllOrders: false,
  currentRestaurantOrder: {},
  isNewRestaurantOrder: false,
  isFetching: false,
  isNewOrder: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.FETCH_GET_ACTIVE_ORDERS_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case actions.FETCH_GET_ACTIVE_ORDERS_SUCCESS: {
      console.log('FETCH_GET_ACTIVE_ORDERS_SUCCESS');
      return {
        ...state,
        allRestaurantOrders: action.payload,
        initialRestaurantAllOrders: true,
        isNewRestaurantOrder: false,
        isFetching: false,
        isNewOrder: true,
      };
    }
    case actions.FETCH_GET_ACTIVE_ORDERS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case actions.SUBSCRIBE_RESTAURANT_ORDERS: {
      return {
        ...state,
        initialWebSocketOrders: true,
      };
    }
    case actions.REQUEST_POSTS_RESTAURANT_ORDERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case actions.RECEIVE_POSTS_RESTAURANT_ORDERS: {
      const orders = action.restaurantOrders.map(function (ord) {
        return { ...ord, key: ord.id };
      });
      return {
        ...state,
        allRestaurantOrders: orders,
        initialRestaurantAllOrders: true,
        isNewRestaurantOrder: false,
        isFetching: false,
        isNewOrder: true,
      };
    }
    case actions.REQUEST_SUBSCRIBE_RESTAURANT_ORDERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case actions.RECEIVE_SUBSCRIBE_RESTAURANT_ORDERS: {
      const order = action.restaurantOrders;
      const orders = state.restaurantOrders.slice();
      const dcOrders = state.dcOrders.slice();
      const yaOrders = state.yaOrders.slice();
      const wlOrders = state.wlOrders.slice();
      const qrOrders = state.qrOrders.slice();

      switch (order.aggregatorId) {
        case 1: {
          /*Delivery Club*/
          const agrIdx = dcOrders.findIndex((b) => b.id === order.id);
          if (agrIdx === -1) {
            dcOrders.unshift(order);
          } else if (dcOrders[agrIdx]?.status !== order?.status) {
            dcOrders[agrIdx] = order;
          }
          break;
        }
        case 2: {
          /*YandexEda*/
          const agrIdx = yaOrders.findIndex((b) => b.id === order.id);
          if (agrIdx === -1) {
            yaOrders.unshift(order);
          } else if (yaOrders[agrIdx]?.status !== order?.status) {
            yaOrders[agrIdx] = order;
          }
          break;
        }
        case 3: {
          /*White Label*/
          const agrIdx = wlOrders.findIndex((b) => b.id === order.id);
          if (agrIdx === -1) {
            wlOrders.unshift(order);
          } else if (wlOrders[agrIdx]?.status !== order?.status) {
            wlOrders[agrIdx] = order;
          }
          break;
        }
        case 4: {
          /*QR*/
          const agrIdx = qrOrders.findIndex((b) => b.id === order.id);
          if (agrIdx === -1) {
            qrOrders.unshift(order);
          } else if (qrOrders[agrIdx]?.status !== order?.status) {
            qrOrders[agrIdx] = order;
          }
          break;
        }
        default:
          break;
      }

      const pIdx = orders.findIndex((b) => b.id === order.id);
      if (pIdx === -1) {
        orders.unshift(order);
        if (
          (action.restaurantOrders.status === 'created' ||
            action.restaurantOrders.status === 'accepted') &&
          typeof action.restaurantOrders.isFirstLoad === 'undefined'
        ) {
          AudioNotification(
            'success',
            'Новый заказ!',
            `Получен новый заказ №${action.restaurantOrders.originalOrderId}!`
          );
        }
      } else if (orders[pIdx].status !== order.status) {
        Notification(
          'info',
          'Статус заказа изменился!',
          `Статус заказ №${
            order.originalOrderId
          } изменился с "${orderStatuses.get(
            orders[pIdx].status
          )}" на "${orderStatuses.get(order.status)}"!`,
          60
        );
        orders[pIdx] = order;
      } else if (
        orders[pIdx].deliveryPrice?.claimId !== order.deliveryPrice?.claimId
      ) {
        Notification(
          'info',
          'Доставка для заказа рассчитана!',
          `Доставка заказ №${order.originalOrderId} рассчитана - стоимость ${order.deliveryPrice?.price} ₽!`,
          60
        );
        orders[pIdx] = order;
      }

      return {
        ...state,
        restaurantOrders: orders,
        yaOrders: yaOrders,
        dcOrders: dcOrders,
        wlOrders: wlOrders,
        qrOrders: qrOrders,
        currentRestaurantOrder: order,
        initialRestaurantOrders: true,
        isNewRestaurantOrder: false,
        isFetching: false,
        isNewOrder: true,
      };
    }
    case actions.CLEAR_RESTAURANT_ORDERS: {
      return {
        ...state,
        initialRestaurantOrders: true,
        yaOrders: [],
        dcOrders: [],
        wlOrders: [],
        currentRestaurantOrder: {},
        restaurantOrders: [],
      };
    }
    case actions.RECEIVE_NOTIFICATION_RESTAURANT_ORDERS: {
      const orders = state.restaurantOrders;
      const index = orders
        .map((order) => order.id)
        .indexOf(parseInt(action.orderId));
      let order = orders[index];

      if (
        action.notificationOrder &&
        order.status !== 'on_the_way' &&
        order.status !== 'picked_up'
      ) {
        if (typeof action.notificationOrder.isFirstLoad === 'undefined')
          Notification(
            'info',
            'Курьер близко к ресторану!',
            `Курьер ${order.courier.name} по заказу №${order.originalOrderId} рядом с рестораном!`
          );
      } else
        return {
          ...state,
        };
      order.notification = action.notificationOrder.type;
      return {
        ...state,
        initialRestaurantOrders: true,
        currentRestaurantOrder: clone(order),
      };
    }
    case actions.UPDATE_RESTAURANT_ORDERS:
      const currentRestaurantOrder = action.order
        ? action.order
        : state.currentRestaurantOrder;

      return {
        ...state,
        restaurantOrders: action.restaurantOrders,
        initialRestaurantOrders: true,
        currentRestaurantOrder: clone(currentRestaurantOrder),
        isNewRestaurantOrder: false,
        isFetching: false,
      };
    case actions.UPDATE_ORDER: {
      const orders = state.restaurantOrders;
      const index = orders.map((order) => order.id).indexOf(action.id);
      if (index !== -1) {
        const order = orders[index];
        order.status = action.status;
        order.statusHistory.push({
          status: action.status,
          date: new Date(moment()).toISOString(),
        });
        return {
          ...state,
          initialRestaurantOrders: true,
          currentRestaurantOrder: clone(order),
        };
      } else if (index === -1) {
        return {
          ...state,
          initialRestaurantOrders: true,
        };
      }
      break;
    }
    case actions.UPDATE_ORDER_STATUS: {
      const currentOrder = {
        status: action.status,
        ...state.currentRestaurantOrder,
      };
      return {
        ...state,
        initialRestaurantOrders: true,
        currentRestaurantOrder: clone(action.order),
      };
    }
    case actions.DELIVERY_ORDER: {
      const orders = state.restaurantOrders;
      const index = orders
        .map((order) => order.id)
        .indexOf(parseInt(action.restaurantOrders.id));

      if (index !== -1) {
        const order = orders[index];
        order.status = action.restaurantOrders.status;
        return {
          ...state,
          initialRestaurantOrders: true,
          currentRestaurantOrder: clone(order),
        };
      } else if (index === -1) {
        return {
          ...state,
          initialRestaurantOrders: true,
        };
      }
      break;
    }
    case actions.RESTAURANT_CANCELLED_ORDER:
    case actions.CUSTOMER_CANCELLED_ORDER: {
      const orders = state.restaurantOrders;
      const index = orders
        .map((order) => order.id)
        .indexOf(parseInt(action.restaurantOrders.id));

      if (index !== -1) {
        const dcOrders = state.dcOrders.slice();
        const yaOrders = state.yaOrders.slice();
        const wlOrders = state.wlOrders.slice();

        console.log({ dcOrders, yaOrders, wlOrders });

        switch (orders[index].aggregatorId) {
          case 1: {
            /*Delivery Club*/
            const agrIdx = dcOrders.findIndex((b) => b.id === orders[index].id);
            if (agrIdx !== -1) state.dcOrders.splice(agrIdx, 1);
            break;
          }
          case 2: {
            /*YandexEda*/
            const agrIdx = yaOrders.findIndex((b) => b.id === orders[index].id);
            if (agrIdx !== -1) state.yaOrders.splice(agrIdx, 1);
            break;
          }
          case 3: {
            /*White Label*/
            const agrIdx = wlOrders.findIndex((b) => b.id === orders[index].id);
            if (agrIdx !== -1) state.wlOrders.splice(agrIdx, 1);
            break;
          }
          default:
            break;
        }

        if (type === actions.CUSTOMER_CANCELLED_ORDER)
          Notification(
            'warning',
            `Заказ №${orders[index].originalOrderId} отменён клиентом`,
            `Клиент отменил заказ №${orders[index].originalOrderId} :С`
          );
        else if (type === actions.RESTAURANT_CANCELLED_ORDER)
          Notification(
            'warning',
            `Заказ №${orders[index].originalOrderId} отменён рестораном`,
            `Ресторан отменил заказ №${orders[index].originalOrderId} :(`
          );
        state.restaurantOrders.splice(index, 1);
        return {
          ...state,
          initialRestaurantOrders: true,
        };
      } else if (index === -1) {
        return {
          ...state,
          initialRestaurantOrders: true,
        };
      }
      break;
    }

    case actions.CLOSE_WEB_SOCKET:
      actions.closeWsOrder();
      return {
        ...initState,
      };
    case actions.LOGOUT:
      console.log('ORDERS LOGOUT');
      return {
        ...initState,
      };

    case actions.REQUEST_POSTS_ORDER_DELIVERY_COST: {
      return {
        ...state,
      };
    }
    case actions.RECEIVE_POSTS_ORDER_DELIVERY_COST: {
      const orderCost = action.cost;
      return {
        ...state,
        orderCost: orderCost,
      };
    }
    default:
      return state;
  }
}
