import { all, takeEvery, takeLatest, put, call } from 'redux-saga/effects';
import { localDataName } from './config';
import actions from './actions';
import authActions from '@iso/redux/auth/actions';
import Notification from '@iso/components/Notification';

import { clearToken } from '@iso/lib/helpers/utility';
import siteConfig from '@iso/config/site.config';

export function* updateQuestionnaireSaga({ questionnaires, questionnaire }) {
  console.log('local storage quest:', questionnaires);
  console.log(JSON.stringify(questionnaires));

  yield localStorage.setItem(localDataName, JSON.stringify(questionnaires));
  yield put({
    type: actions.UPDATE_QUESTIONNAIRE,
    questionnaires,
    questionnaire,
  });
}

const onUserQuestionnaireRequest = async () => {
  const userId = localStorage.getItem('id');
  const response = await fetch(`${siteConfig.apiUrl}/userForm/${userId}`);
  return response.json();
};

function* fetchUserQuestionnaire() {
  try {
    let userQuestionnaire = yield call(onUserQuestionnaireRequest);
    yield put(actions.fetchUserQuestionnaireSuccess(userQuestionnaire));
  } catch (error) {
    yield put(actions.fetchUserQuestionnaireFailure(error));
  }
}

const onSubmitUserQuestionnaireRequest = async ({
  userId,
  userQuestionnaire,
  files,
}) => {
  let links = [];
  if (!!files && Array.isArray(files) && files.length) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(file.dataFormat, file.originFileObj);
    });

    const response = await fetch(`${siteConfig.apiUrl}/upload`, {
      crossDomain: true,
      method: 'POST',
      mode: 'cors',
      body: formData,
    });
    links = (await response.json()).fileLinks;
  }

  const lsUserId = localStorage.getItem('id');
  /*TODO: check that userId equals localStorage.getItem('id')*/

  const request = { ...userQuestionnaire, fileLinks: links };
  let method;
  let url;
  if (!('id' in userQuestionnaire)) {
    method = 'POST';
    url = `${siteConfig.apiUrl}/guest/form`;
  } else {
    method = 'PUT';
    url = `${siteConfig.apiUrl}/guest/updateUserForm`;
  }
  // const method = 'POST';
  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(request),

    crossDomain: true,
  });
  return response.json();
};

function* submitUserQuestionnaire(action) {
  try {
    let userQuestionnaire = yield call(
      onSubmitUserQuestionnaireRequest,
      action.payload
    );
    if (!action.payload.userQuestionnaire.id) {
      Notification('success', 'Заявка успешно заполнена!', 'Заявка создана.');
    } else {
      Notification('success', 'Заявка обновлена!', 'Заявка обновлена.');
    }
    yield put(actions.submitUserQuestionnaireSuccess(userQuestionnaire));
  } catch (error) {
    yield put(actions.submitUserQuestionnaireFailure(error));
  }
}

const onUserRequisitesRequest = async () => {
  const userId = localStorage.getItem('id');
  const response = await fetch(`${siteConfig.apiUrl}/userRequisites/${userId}`);
  return response.json();
};

function* fetchUserRequisites() {
  try {
    let userQuestionnaire = yield call(onUserRequisitesRequest);
    yield put(actions.fetchUserRequisitesSuccess(userQuestionnaire));
  } catch (error) {
    yield put(actions.fetchUserRequisitesFailure(error));
  }
}

const onSubmitUserRequisitesRequest = async ({ userId, userRequisites }) => {
  const lsUserId = localStorage.getItem('id');
  /*TODO: check that userId equals localStorage.getItem('id')*/

  let method;
  let url;
  if (!('id' in userRequisites)) {
    method = 'POST';
    url = `${siteConfig.apiUrl}/guest/requisites/`;
  } else {
    // method = 'PUT';
    method = 'POST';
    url = `${siteConfig.apiUrl}/guest/requisites/`;
  }
  // const method = 'POST';
  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(userRequisites),

    crossDomain: true,
  });
  return response.json();
};

function* submitUserRequisites(action) {
  try {
    let userRequisites = yield call(
      onSubmitUserRequisitesRequest,
      action.payload
    );
    yield put(actions.submitUserRequisitesSuccess(userRequisites));
    if (!action.payload.userRequisites.id) {
      Notification('success', 'Заявка успешно заполнена!', 'Заявка создана.');
    } else {
      Notification('success', 'Заявка обновлена!', 'Заявка обновлена.');
    }
  } catch (error) {
    yield put(actions.submitUserRequisitesFailure(error));
  }
}

const onUserBrandsRequest = async () => {
  const userId = localStorage.getItem('id');
  const response = await fetch(`${siteConfig.apiUrl}/guestBrands/${userId}`);
  return response.json();
};

function* fetchUserBrands() {
  try {
    let userBrands = yield call(onUserBrandsRequest);
    yield put(actions.fetchUserBrandsSuccess(userBrands));
  } catch (error) {
    yield put(actions.fetchUserBrandsFailure(error));
  }
}

const onAddUserBrandRequest = async ({ userId, brandId }) => {
  const storeUserId = parseInt(localStorage.getItem('id'));
  if (storeUserId !== userId) {
    throw 'Request from not logged in user';
  }
  const response = await fetch(`${siteConfig.apiUrl}/guestBrands`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({ userId, brandId }),

    crossDomain: true,
  });
  return response.json();
};

function* addUserBrand(action) {
  try {
    let userBrands = yield call(onAddUserBrandRequest, action.payload);
    yield put(actions.addUserBrandsSuccess(action.payload));
  } catch (error) {
    yield put(actions.addUserBrandsFailure(error));
  }
}

const onRemoveUserBrandRequest = async ({ userId, brandId }) => {
  const storeUserId = parseInt(localStorage.getItem('id'));
  if (storeUserId !== userId) {
    throw 'Request from not logged in user';
  }
  const response = await fetch(`${siteConfig.apiUrl}/guestBrands`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({ userId, brandId }),

    crossDomain: true,
  });
  return response.json();
};

function* removeUserBrand(action) {
  try {
    let userBrands = yield call(onRemoveUserBrandRequest, action.payload);
    yield put(actions.removeUserBrandsSuccess(action.payload));
  } catch (error) {
    yield put(actions.removeUserBrandsFailure(error));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.UPDATE_QUESTIONNAIRE_SAGA, updateQuestionnaireSaga),
    yield takeLatest(
      actions.FETCH_USER_QUESTIONNAIRE_START,
      fetchUserQuestionnaire
    ),
    yield takeLatest(
      actions.SUBMIT_USER_QUESTIONNAIRE_START,
      submitUserQuestionnaire
    ),
    yield takeLatest(actions.FETCH_USER_REQUISITES_START, fetchUserRequisites),
    yield takeLatest(
      actions.SUBMIT_USER_REQUISITES_START,
      submitUserRequisites
    ),
    yield takeLatest(actions.FETCH_USER_BRANDS_START, fetchUserBrands),
    yield takeLatest(actions.ADD_USER_BRAND_START, addUserBrand),
    yield takeLatest(actions.REMOVE_USER_BRAND_START, removeUserBrand),
  ]);
}

export function* logout() {
  yield takeEvery(authActions.LOGOUT, function* () {
    console.log();
    yield put({
      type: actions.LOGOUT,
    });
  });
}
