export default {
  siteName: 'Pizzabox.ai',
  siteIcon: 'ion-android-cloud-outline',
  version: '0.0.034',
  footerText: `@ ${new Date().getFullYear()} Localbytes`,
  footerTextResto: `@ ${new Date().getFullYear()} RestoGPT AI`,
  enableAnimatedRoute: false,
  // apiUrl: 'http://deliverybrands.ru/api/v1',
  // apiWSUrl: 'ws://deliverybrands.ru/wsapi/v1',
  // apiUrl: 'http://127.0.0.1:35000',
  // apiWSUrl: 'ws://127.0.0.1:35000/wsapi/v1',
  apiUrl: 'https://orderbyte.io/api/v1',
  apiWSUrl: 'wss://orderbyte.io/wsapi/v1',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
