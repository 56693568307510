const checklistActions = {
  FETCH_CHECKLIST_DATA_START: 'FETCH_CHECKLIST_DATA_START',
  FETCH_CHECKLIST_DATA_SUCCESS: 'FETCH_CHECKLIST_DATA_SUCCESS',
  FETCH_CHECKLIST_DATA_FAILURE: 'FETCH_CHECKLIST_DATA_FAILURE',

  fetchChecklistDataStart: (brandId) => ({
    type: checklistActions.FETCH_CHECKLIST_DATA_START,
    brandId,
  }),

  fetchChecklistDataSuccess: (checklist) => ({
    type: checklistActions.FETCH_CHECKLIST_DATA_SUCCESS,
    payload: checklist,
  }),

  fetchChecklistDataFailure: (error) => ({
    type: checklistActions.FETCH_CHECKLIST_DATA_FAILURE,
    payload: error,
  }),
};

export default checklistActions;
