import notifications from './Feedback/Notification';
import Button from './uielements/button';
import React from 'react';
import song from '@iso/assets/audio/Sound_16368.mp3';

const player = new Audio(song);

const createNotification = (type, message, description) => {
  if (
    localStorage.getItem('notices') === false ||
    localStorage.getItem('notices') === 'false'
  ) {
    player.addEventListener(
      'ended',
      function () {
        player.currentTime = 0;
        player.play().then(() => {});
      },
      false
    );
    const playPromise = player.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          console.log('audio played auto');
        })
        .catch((error) => {
          console.log('playback prevented', error);
        });
    }
  }

  const key = `open${Date.now()}`;
  const close = () => {
    player.pause();
  };
  const btnClick = function () {
    player.pause();
    notifications.close(key);
  };
  const btn = (
    <Button type="primary" size="small" onClick={btnClick}>
      Закрыть
    </Button>
  );

  notifications.open({
    type: 'success',
    message,
    description,
    btn,
    key,
    onClose: close,
    duration: 0,
    style: { borderRadius: '20px' },
  });
};
export default createNotification;
