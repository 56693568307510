import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

const InjectMassage = (props) => {
  return <FormattedMessage {...props}>{props.children}</FormattedMessage>;
};
export default injectIntl(InjectMassage, {
  withRef: false,
});

export let nl2br = function (nodes) {
  let totalIdx = 0;
  let mappedNodes = nodes.map((node) =>
    typeof node === 'string'
      ? node
          .split('\n')
          .reduce(
            (acc, text, idx) =>
              acc ? acc.concat(<br key={'br' + totalIdx++} />, text) : [text],
            undefined
          )
      : node
  );
  return mappedNodes.reduce((nodes, node) => nodes.concat(node), []);
};
