import siteConfig from '@iso/config/site.config';

const profileActions = {
  FETCH_PROFILE_DATA_START: 'FETCH_PROFILE_DATA_START',
  FETCH_USER_PROFILE_DATA_START: 'FETCH_USER_PROFILE_DATA_START',
  FETCH_PROFILE_DATA_SUCCESS: 'FETCH_PROFILE_DATA_SUCCESS',
  FETCH_PROFILE_DATA_FAILURE: 'FETCH_PROFILE_DATA_FAILURE',
  FETCH_RESTAURANT_DATA_START: 'FETCH_RESTAURANT_DATA_START',
  FETCH_RESTAURANT_DATA_SUCCESS: 'FETCH_RESTAURANT_DATA_SUCCESS',
  FETCH_RESTAURANT_DATA_FAILURE: 'FETCH_RESTAURANT_DATA_FAILURE',
  SET_PROFILE_DATA: 'SET_PROFILE_DATA',
  FETCH_SET_RESTAURANT_DATA_START: 'FETCH_SET_RESTAURANT_DATA_START',
  FETCH_SET_RESTAURANT_DATA_SUCCESS: 'FETCH_SET_RESTAURANT_DATA_SUCCESS',
  FETCH_SET_RESTAURANT_DATA_FAILURE: 'FETCH_SET_RESTAURANT_DATA_FAILURE',
  FETCH_ADD_BRAND: 'FETCH_ADD_BRAND',
  FETCH_ADD_BRAND_SUCCESS: 'FETCH_ADD_BRAND_SUCCESS',
  FETCH_ADD_BRAND_FAILURE: 'FETCH_ADD_BRAND_FAILURE',
  FETCH_USER_OWN_BRANDS_START: 'FETCH_USER_OWN_BRANDS_START',
  FETCH_USER_OWN_BRANDS_SUCCESS: 'FETCH_USER_OWN_BRANDS_SUCCESS',
  FETCH_USER_OWN_BRANDS_FAILURE: 'FETCH_USER_OWN_BRANDS_FAILURE',
  FETCH_ADD_USER_OWN_BRAND: 'FETCH_ADD_USER_OWN_BRAND',
  FETCH_ADD_USER_OWN_BRAND_SUCCESS: 'FETCH_ADD_USER_OWN_BRAND_SUCCESS',
  FETCH_ADD_USER_OWN_BRAND_FAILURE: 'FETCH_ADD_USER_OWN_BRAND_FAILURE',
  UPDATE_BRAND: 'UPDATE_BRAND',
  UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
  UPDATE_BRAND_FAILURE: 'UPDATE_BRAND_FAILURE',

  DELETE_BRAND: 'DELETE_BRAND',
  DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
  DELETE_BRAND_FAILURE: 'DELETE_BRAND_FAILURE',

  FETCH_VERIFY_EMAIL_START: 'FETCH_VERIFY_EMAIL_START',
  FETCH_VERIFY_EMAIL_SUCCESS: 'FETCH_VERIFY_EMAIL_SUCCESS',
  FETCH_VERIFY_EMAIL_FAILURE: 'FETCH_VERIFY_EMAIL_FAILURE',
  LOGOUT: 'LOGOUT',

  fetchProfileDataStart: () => ({
    type: profileActions.FETCH_PROFILE_DATA_START,
  }),
  fetchUserProfileDataStart: (userId) => ({
    type: profileActions.FETCH_USER_PROFILE_DATA_START,
    payload: userId,
  }),
  fetchProfileDataSuccess: (profile) => ({
    type: profileActions.FETCH_PROFILE_DATA_SUCCESS,
    payload: profile,
  }),
  fetchProfileDataFailure: (error) => ({
    type: profileActions.FETCH_PROFILE_DATA_FAILURE,
    payload: error,
  }),
  fetchRestaurantDataStart: (userId) => ({
    type: profileActions.FETCH_RESTAURANT_DATA_START,
    payload: userId,
  }),
  fetchRestaurantDataSuccess: (profile) => ({
    type: profileActions.FETCH_RESTAURANT_DATA_SUCCESS,
    payload: profile,
  }),
  fetchRestaurantDataFailure: (error) => ({
    type: profileActions.FETCH_RESTAURANT_DATA_FAILURE,
    payload: error,
  }),

  fetchSetRestaurantDataStart: (userId, restaurant) => ({
    type: profileActions.FETCH_SET_RESTAURANT_DATA_START,
    payload: { userId, restaurant },
  }),
  fetchSetRestaurantDataSuccess: (profile) => ({
    type: profileActions.FETCH_SET_RESTAURANT_DATA_SUCCESS,
    payload: profile,
  }),
  fetchSetRestaurantDataFailure: (error) => ({
    type: profileActions.FETCH_SET_RESTAURANT_DATA_FAILURE,
    payload: error,
  }),

  fetchAddBrand: (brand) => ({
    type: profileActions.FETCH_ADD_BRAND,
    payload: brand,
  }),
  fetchAddBrandSuccess: (brand) => ({
    type: profileActions.FETCH_ADD_BRAND_SUCCESS,
    payload: brand,
  }),
  fetchAddBrandFailure: (error) => ({
    type: profileActions.FETCH_ADD_BRAND_FAILURE,
    payload: error,
  }),

  fetchAddUserOwnBrand: (brand, userId) => ({
    type: profileActions.FETCH_ADD_USER_OWN_BRAND,
    payload: { brand, userId },
  }),
  fetchAddUserOwnBrandSuccess: (brand) => ({
    type: profileActions.FETCH_ADD_USER_OWN_BRAND_SUCCESS,
    payload: brand,
  }),
  fetchAddUserOwnBrandFailure: (error) => ({
    type: profileActions.FETCH_ADD_USER_OWN_BRAND_FAILURE,
    payload: error,
  }),

  fetchUserOwnBrandsStart: (restaurantId) => ({
    type: profileActions.FETCH_USER_OWN_BRANDS_START,
    payload: restaurantId,
  }),
  fetchUserOwnBrandsSuccess: (profile) => ({
    type: profileActions.FETCH_USER_OWN_BRANDS_SUCCESS,
    payload: profile,
  }),
  fetchUserOwnBrandsFailure: (error) => ({
    type: profileActions.FETCH_USER_OWN_BRANDS_FAILURE,
    payload: error,
  }),

  updateBrand: (brand) => ({
    type: profileActions.UPDATE_BRAND,
    payload: brand,
  }),
  updateBrandSuccess: (brand) => ({
    type: profileActions.UPDATE_BRAND_SUCCESS,
    payload: brand,
  }),
  updateBrandFailure: (error) => ({
    type: profileActions.UPDATE_BRAND_FAILURE,
    payload: error,
  }),

  deleteBrand: (brand) => ({
    type: profileActions.DELETE_BRAND,
    payload: brand,
  }),
  deleteBrandSuccess: (brand) => ({
    type: profileActions.DELETE_BRAND_SUCCESS,
    payload: brand,
  }),
  deleteBrandFailure: (error) => ({
    type: profileActions.DELETE_BRAND_FAILURE,
    payload: error,
  }),

  fetchVerifyEmailStart: (token) => ({
    type: profileActions.FETCH_VERIFY_EMAIL_START,
    payload: { token },
  }),
  fetchVerifyEmailSuccess: () => ({
    type: profileActions.FETCH_VERIFY_EMAIL_SUCCESS,
  }),

  addRestaurantBrand: (restaurantId, brandId) => {
    console.log('addRestaurantBrand', restaurantId, brandId);
    return (dispatch) => {
      return fetch(
        `${siteConfig.apiUrl}/restaurantBrands/${restaurantId}/${brandId}`,
        {
          crossDomain: true,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
        }
      ).then(() => {});
    };
  },

  deleteRestaurantBrand: (restaurantId, brandId) => {
    console.log('deleteRestaurantBrand', restaurantId, brandId);
    return (dispatch) => {
      return fetch(
        `${siteConfig.apiUrl}/restaurantBrands/${restaurantId}/${brandId}`,
        {
          crossDomain: true,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
        }
      ).then(() => {});
    };
  },
};

export default profileActions;
