// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import localEatsActions from './actions';
import siteConfig from '@iso/config/site.config';

export const onMarketplaceDataRequest = async (code) =>
  await fetch(`${siteConfig.apiUrl}/marketplace?marketplace_code=` + code)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchMarketplaceDataEffect({ payload }) {
  try {
    let StopLists = yield call(onMarketplaceDataRequest, payload);
    yield put(localEatsActions.fetchMarketplaceDataSuccess(StopLists));
  } catch (error) {
    yield put(localEatsActions.fetchMarketplaceDataFailure(error));
  }
}

export const onMarketplaceBrandsRequest = async (code) =>
  await fetch(
    `${siteConfig.apiUrl}/marketplaceBrands?marketplace_code=` +
      code +
      '&delivery=' +
      (localStorage.getItem('delivery-type')
        ? localStorage.getItem('delivery-type')
        : 'courier')
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchMarketplaceBrandsEffect({ payload }) {
  try {
    let StopLists = yield call(onMarketplaceBrandsRequest, payload);
    yield put(localEatsActions.fetchMarketplaceBrandsSuccess(StopLists));
  } catch (error) {
    yield put(localEatsActions.fetchMarketplaceBrandsFailure(error));
  }
}

export const onMarketplaceFilterBrandsRequest = async (code, filters) =>
  await fetch(
    `${siteConfig.apiUrl}/filterBrandsMarketplace?marketplace_code=` +
      code +
      '&delivery=' +
      (localStorage.getItem('delivery-type')
        ? localStorage.getItem('delivery-type')
        : 'courier'),
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({ filters: filters }),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchMarketplaceFilterBrandsEffect({ payload, filters }) {
  try {
    console.log({ payload, filters });
    let StopLists = yield call(
      onMarketplaceFilterBrandsRequest,
      payload,
      filters
    );
    yield put(localEatsActions.fetchMarketplaceFilterBrandsSuccess(StopLists));
  } catch (error) {
    yield put(localEatsActions.fetchMarketplaceFilterBrandsFailure(error));
  }
}

export const onMarketplaceOffersRequest = async (code) =>
  await fetch(`${siteConfig.apiUrl}/marketplaceOffers?marketplace_code=` + code)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchMarketplaceOffersEffect({ payload }) {
  try {
    let offers = yield call(onMarketplaceOffersRequest, payload);
    yield put(localEatsActions.fetchMarketplaceOffersSuccess(offers));
  } catch (error) {
    yield put(localEatsActions.fetchMarketplaceOffersFailure(error));
  }
}

export const onFilterRequest = async (code) =>
  await fetch(`${siteConfig.apiUrl}/getFilterItems?marketplace_code=` + code)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchMenuFilterEffect({ payload }) {
  try {
    let filters = yield call(onFilterRequest, payload);
    yield put(localEatsActions.fetchMenuFilterSuccess(filters));
  } catch (error) {
    yield put(localEatsActions.fetchMenuFilterFailure(error));
  }
}

export default function* localEatsSaga() {
  yield all([
    takeEvery(localEatsActions.FETCH_MENU_FILTER_START, fetchMenuFilterEffect),

    takeEvery(
      localEatsActions.FETCH_MARKETPLACE_DATA_START,
      fetchMarketplaceDataEffect
    ),
    takeEvery(
      localEatsActions.FETCH_MARKETPLACE_BRANDS_START,
      fetchMarketplaceBrandsEffect
    ),
    takeEvery(
      localEatsActions.FETCH_MARKETPLACE_FILTER_BRANDS_START,
      fetchMarketplaceFilterBrandsEffect
    ),
    takeEvery(
      localEatsActions.FETCH_MARKETPLACE_OFFERS_START,
      fetchMarketplaceOffersEffect
    ),
  ]);
}
