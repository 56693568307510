import profileActions from './actions';

const INITIAL_DATA = {
  data: null,
  ownBrands: [],
  loading: true,
  ownLoading: true,
  restaurant: null,
  initialRestaurant: true,
  error: null,
};
export default function profileReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case profileActions.FETCH_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case profileActions.FETCH_PROFILE_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case profileActions.FETCH_RESTAURANT_DATA_START:
      return {
        ...state,
        restaurant: null,
        initialRestaurant: true,
      };
    case profileActions.FETCH_RESTAURANT_DATA_SUCCESS:
      return {
        ...state,
        restaurant: action.payload,
        initialRestaurant: false,
        error: null,
      };
    case profileActions.FETCH_RESTAURANT_DATA_FAILURE:
      return {
        ...state,
        initialRestaurant: false,
        error: action.payload,
      };

    case profileActions.FETCH_SET_RESTAURANT_DATA_SUCCESS:
      return {
        ...state,
        restaurant: action.payload,
        initialRestaurant: true,
        error: null,
      };
    case profileActions.FETCH_SET_RESTAURANT_DATA_FAILURE:
      return {
        ...state,
        initialRestaurant: false,
        error: action.payload,
      };

    case profileActions.FETCH_ADD_BRAND_SUCCESS:
    case profileActions.UPDATE_BRAND_SUCCESS: {
      const brand = action.payload;
      const brands =
        state.ownBrands !== null
          ? state.ownBrands.slice()
          : state.data.all.slice();
      const pIdx = brands.findIndex((b) => b.id === brand.id);

      console.log(pIdx, brand, brands, state);
      if (pIdx === -1) {
        brands.push(brand);
      } else {
        brands[pIdx] = brand;
      }

      if (state.ownBrands !== null)
        return {
          ...state,
          ownBrands: brands,
        };
      else
        return {
          ...state,
          data: {
            ...state.data,
            all: brands,
          },
        };
    }
    case profileActions.DELETE_BRAND_SUCCESS: {
      const brand = action.payload;
      const brands =
        state.data !== null ? state.data.all.slice() : state.ownBrands.slice();
      const ownBrands = state.ownBrands.slice();
      const pIdx = brands.findIndex((b) => b.id === brand.id);
      const pOwnIdx = ownBrands.findIndex((b) => b.id === brand.id);

      if (pIdx !== -1) delete brands[pIdx];
      if (pOwnIdx !== -1) delete ownBrands[pOwnIdx];

      return {
        ...state,
        data: {
          ...state.data,
          all: brands,
        },
        ownBrands: ownBrands,
      };
    }
    case profileActions.FETCH_ADD_BRAND_FAILURE:
    case profileActions.UPDATE_BRAND_FAILURE:
    case profileActions.DELETE_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case profileActions.FETCH_USER_OWN_BRANDS_SUCCESS:
      return {
        ...state,
        ownBrands: action.payload.brands,
        ownLoading: false,
      };
    case profileActions.FETCH_USER_OWN_BRANDS_FAILURE:
      return {
        ...state,
        ownLoading: false,
        error: action.payload,
      };
    case profileActions.FETCH_ADD_USER_OWN_BRAND:
      return {
        ...state,
        ownLoading: true,
      };
    case profileActions.FETCH_ADD_USER_OWN_BRAND_SUCCESS: {
      const brand = action.payload;

      return {
        ...state,
        ownLoading: false,
        ownBrands: [...state.ownBrands, brand],
      };
    }
    case profileActions.FETCH_ADD_USER_OWN_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        ownLoading: false,
        error: action.payload,
      };
    case profileActions.FETCH_VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case profileActions.LOGOUT:
      return INITIAL_DATA;
    default:
      return state;
  }
}
