import siteConfig from '@iso/config/site.config';
import { newTestOrder, newTestOrderBrand } from './config';
import Moment from 'moment';

let ws;

const actions = {
  REQUEST_POSTS_RESTAURANT_ORDERS: 'REQUEST_POSTS_RESTAURANT_ORDERS',
  RECEIVE_POSTS_RESTAURANT_ORDERS: 'RECEIVE_POSTS_RESTAURANT_ORDERS',
  REQUEST_SUBSCRIBE_RESTAURANT_ORDERS: 'REQUEST_SUBSCRIBE_RESTAURANT_ORDERS',
  RECEIVE_SUBSCRIBE_RESTAURANT_ORDERS: 'RECEIVE_SUBSCRIBE_RESTAURANT_ORDERS',
  RECEIVE_NOTIFICATION_RESTAURANT_ORDERS:
    'RECEIVE_NOTIFICATION_RESTAURANT_ORDERS',

  UPDATE_RESTAURANT_ORDERS: 'UPDATE_RESTAURANT_ORDERS',
  UPDATE_RESTAURANT_ORDERS_SAGA: 'UPDATE_RESTAURANT_ORDERS_SAGA',
  UPDATE_ORDER: 'UPDATE_ORDER',
  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
  DELIVERY_ORDER: 'DELIVERY_ORDER',
  CUSTOMER_CANCELLED_ORDER: 'CUSTOMER_CANCELLED_ORDER',
  RESTAURANT_CANCELLED_ORDER: 'RESTAURANT_CANCELLED_ORDER',
  SUBSCRIBE_RESTAURANT_ORDERS: 'SUBSCRIBE_RESTAURANT_ORDERS',
  LOGOUT: 'LOGOUT',

  CLEAR_RESTAURANT_ORDERS: 'CLEAR_RESTAURANT_ORDERS',
  CLOSE_WEB_SOCKET: 'CLEAR_RESTAURANT_ORDERS',

  /*Все активные заказы*/
  FETCH_GET_ACTIVE_ORDERS_START: 'FETCH_GET_ACTIVE_ORDERS_START',
  FETCH_GET_ACTIVE_ORDERS_SUCCESS: 'FETCH_GET_ACTIVE_ORDERS_SUCCESS',
  FETCH_GET_ACTIVE_ORDERS_FAILURE: 'FETCH_GET_ACTIVE_ORDERS_FAILURE',

  REQUEST_POSTS_ORDER_DELIVERY_COST: 'REQUEST_POSTS_ORDER_DELIVERY_COST',
  RECEIVE_POSTS_ORDER_DELIVERY_COST: 'RECEIVE_POSTS_ORDER_DELIVERY_COST',

  fetchGetActiveOrdersStart: () => ({
    type: actions.FETCH_GET_ACTIVE_ORDERS_START,
  }),
  fetchGetActiveOrdersSuccess: (activeOrders) => ({
    type: actions.FETCH_GET_ACTIVE_ORDERS_SUCCESS,
    payload: activeOrders,
  }),
  fetchGetActiveOrdersFailure: (error) => ({
    type: actions.FETCH_GET_ACTIVE_ORDERS_FAILURE,
    payload: error,
  }),

  getRestaurantOrders: (restaurantId) => {
    return (dispatch) => {
      const url =
        localStorage.getItem('userRole') === 'admin'
          ? `${siteConfig.apiUrl}/allorders`
          : `${siteConfig.apiUrl}/allorders/${restaurantId}`;

      dispatch({
        type: actions.REQUEST_POSTS_RESTAURANT_ORDERS,
      });
      return fetch(url)
        .then((response) => response.json())
        .then((json) => {
          if (json['orders'].length !== 0) {
            dispatch({
              type: actions.RECEIVE_POSTS_RESTAURANT_ORDERS,
              restaurantOrders: json['orders'],
            });
          }
        });
    };
  },
  getOrderDeliveryCost: (orderId) => {
    return (dispatch) => {
      const url = `${siteConfig.apiUrl}/deliverycost/${orderId}`;
      dispatch({
        type: actions.REQUEST_POSTS_ORDER_DELIVERY_COST,
      });
      return fetch(url)
        .then((response) => response.json())
        .then((json) => {
          if (json['cost']) {
            /*console.log(json);
            dispatch({
              type: actions.RECEIVE_POSTS_ORDER_DELIVERY_COST,
              cost: json['cost'],
            });*/
            return json['cost'];
          }
        });
    };
  },
  applyDeliveryOrder: (orderId) => {
    return (dispatch) => {
      return fetch(`${siteConfig.apiUrl}/applydelivery/${orderId}`, {
        crossDomain: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      }).then(() => {});
    };
  },

  subscribeRestaurantOrders: () => {
    if (
      localStorage.getItem('id_token') &&
      localStorage.getItem('restaurantId') !== null
    ) {
      return (dispatch) => {
        function connect() {
          const wsUrl =
            localStorage.getItem('userRole') === 'admin'
              ? `${siteConfig.apiWSUrl}/subscribeOrders`
              : `${siteConfig.apiWSUrl}/subscribeOrders/${localStorage.getItem(
                  'restaurantId'
                )}`;
          document.cookie =
            'X-Authorization=' + localStorage.getItem('id_token') + '; path=/';

          ws?.close();

          ws = new WebSocket(wsUrl);
          ws.onclose = function (e) {
            if (
              localStorage.getItem('restaurantId') &&
              localStorage.getItem('restaurantId') !== 0
            ) {
              console.log(
                Moment(new Date()).format('HH:mm:ss'),
                ': Socket is closed. Reconnect will be attempted in 5 second.',
                e
              );
              /*dispatch({
                type: actions.CLEAR_RESTAURANT_ORDERS,
              });*/
              setTimeout(function () {
                connect();
              }, 5000);
            } else {
              console.log(
                'Socket will be closed. Restaurant id = 0, reconnection disabled.',
                e
              );
            }
          };

          ws.onerror = function (err) {
            console.error('Socket encountered error: ', err, 'Closing socket');
            ws.close();
          };

          dispatch({
            type: actions.SUBSCRIBE_RESTAURANT_ORDERS,
          });

          ws.addEventListener('message', (e) => {
            const js = JSON.parse(e.data);

            console.log('WS order', js);
            if (
              typeof js.customer !== 'undefined' &&
              js.status !== 'delivered' &&
              js.status !== 'restaurant_cancelled' &&
              js.status !== 'customer_cancelled'
            ) {
              dispatch({
                type: actions.RECEIVE_SUBSCRIBE_RESTAURANT_ORDERS,
                restaurantOrders: js,
              });
            } else if (js.status === 'delivered') {
              dispatch({
                type: actions.DELIVERY_ORDER,
                restaurantOrders: js,
              });
            } else if (js.status === 'customer_cancelled') {
              dispatch({
                type: actions.CUSTOMER_CANCELLED_ORDER,
                restaurantOrders: js,
              });
            } else if (js.status === 'restaurant_cancelled') {
              dispatch({
                type: actions.RESTAURANT_CANCELLED_ORDER,
                restaurantOrders: js,
              });
            } else if (
              typeof js.type !== 'undefined' &&
              typeof js.orderId !== 'undefined' &&
              typeof js.id !== 'undefined'
            ) {
              dispatch({
                type: actions.RECEIVE_NOTIFICATION_RESTAURANT_ORDERS,
                notificationOrder: js,
                orderId: js.orderId,
              });
            }
          });
          return ws;
        }
        connect();
      };
    }
  },
  setOrderStatus: (order, status) => {
    order.status = status;
    return (dispatch) => {
      return fetch(
        `${siteConfig.apiUrl}/${order.brandId}/updateOrder/${order.restaurantId}/${order.orderId}`,
        {
          crossDomain: true,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify(order),
        }
      ).then(() => {
        dispatch({
          type: actions.UPDATE_ORDER,
          status: order.status,
          originalOrderId: order.originalOrderId,
          id: order.orderId,
        });
      });
    };
  },
  sendTestOrder: () => {
    const order = newTestOrder;
    const randomNumber = Math.floor(Math.random() * newTestOrderBrand.length);
    order.originalOrderId =
      Math.floor(Math.random() * 100) +
      '-' +
      Math.floor(Math.random() * 10000) +
      '-' +
      Math.floor(Math.random() * 10000);
    order.createdAt = Moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');
    return (dispatch) => {
      return fetch(
        `${siteConfig.apiUrl}/${
          newTestOrderBrand[randomNumber]
        }/orders/${localStorage.getItem('restaurantId')}`,
        {
          crossDomain: true,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify(order),
        }
      ).then(() => {});
    };
  },
  updateOrder: (order) => {
    return (dispatch) => {};
  },
  closeWsOrder: () => {
    ws?.close();
    console.log('WebSocket close:', ws);
  },
};
export default actions;
