import styled from 'styled-components';

const PwaNotificationWrapper = styled.div`
  padding: 14px;
  h4 {
    font-size: 26px;
    //margin-bottom: 20px;
    text-align: center;
    line-height: 122%;
  }

  .pwa-modal-content {
    width: 100%;
    height: auto;
    display: flex;
    //margin-bottom: 30px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .pwa-modal-content img {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    border-radius: 12px;
  }
  .pwa-restaurant-info {
    //width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .pwa-close-btn,
  .pwa-install-btn {
    width: 48%;
    border: none;
    padding: 10px;
    margin-right: 2%;
    border-radius: 30px;
  }

  .pwa-close-btn {
    color: #000;
    background: #efefef;
  }
  .pwa-install-btn {
    color: #fff;
    background: #19bf88;
    margin-right: 0;
  }
`;

export default PwaNotificationWrapper;
