import actions from './actions';
import moment from 'moment';
import Notification from '@iso/components/Notification';

const INITIAL_DATA = {
  data: null,
  partnerSettings: null,
  brandSettings: null,
  loadingPartnerSettings: true,
  loading: true,
  isLoading: false,
  error: null,
};
export default function accountSettingsReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.FETCH_RKEEPER_SETTINGS_SUCCESS:
    case actions.SUBMIT_RKEEPER_SETTINGS_SUCCESS:
      return {
        ...state,
        rKeeperSettings: action.payload,
        loading: false,
        isLoading: false,
        error: null,
      };
    case actions.FETCH_RKEEPER_SETTINGS_FAILURE:
    case actions.SUBMIT_RKEEPER_SETTINGS_FAILURE:
    case actions.FETCH_IIKO_SETTINGS_FAILURE:
    case actions.FETCH_POS_INTEGRATION_FAILURE:
      return {
        ...state,
        loading: false,
        isLoading: false,
        error: action.payload,
      };
    case actions.FETCH_BRAND_SETTINGS_SUCCESS: {
      return {
        ...state,
        brandSettings: action.payload.brandSettings,
        isLoading: false,
        error: null,
      };
    }
    case actions.FETCH_PARTNER_SETTINGS_SUCCESS:
    case actions.SUBMIT_PARTNER_SETTINGS_SUCCESS: {
      const settings = {
        ...action.payload.partnerSettings,
        date: moment(new Date(action.payload.partnerSettings.date)),
      };
      return {
        ...state,
        partnerSettings: settings,
        loadingPartnerSettings: false,
        error: null,
      };
    }
    case actions.FETCH_PARTNER_SETTINGS_FAILURE:
    case actions.SUBMIT_PARTNER_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.SUBMIT_PARTNER_PLAN_START:
    case actions.SUBMIT_CREATE_SITE_START:
    case actions.SUBMIT_CREATE_MENU_START:
    case actions.SUBMIT_FILL_BRAND_START:
    case actions.SUBMIT_SITE_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SUBMIT_PARTNER_PLAN_SUCCESS:
    case actions.SUBMIT_CREATE_SITE_SUCCESS:
    case actions.SUBMIT_CREATE_MENU_SUCCESS:
    case actions.SUBMIT_FILL_BRAND_SUCCESS:
    case actions.SUBMIT_SITE_ADDRESS_SUCCESS:
    case actions.SUBMIT_SITE_ADDRESS_DOMAIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        partnerSettings: {
          ...state.partnerSettings,
          ...action.payload.data,
        },
      };
    case actions.SUBMIT_PARTNER_PLAN_FAILURE:
    case actions.SUBMIT_CREATE_SITE_FAILURE:
    case actions.SUBMIT_CREATE_MENU_FAILURE:
    case actions.SUBMIT_FILL_BRAND_FAILURE:
    case actions.SUBMIT_SITE_ADDRESS_FAILURE:
      Notification(
        'error',
        'Что-то пошло не так :(',
        'Не удалось выполнить действие, данные не сохранены!'
      );
      return {
        ...state,
        loading: false,
        isLoading: false,
        error: action.payload,
      };

    case actions.FETCH_IIKO_SETTINGS_SUCCESS:
    case actions.SUBMIT_IIKO_SETTINGS_SUCCESS:
      return {
        ...state,
        iikoSettings: action.payload.iikoSettings,
        loading: false,
        isLoading: false,
        error: null,
      };
    case actions.FETCH_POS_INTEGRATION_SUCCESS:
      return {
        ...state,
        posIntegration: action.payload.posIntegration,
        loading: false,
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
}
