// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import menuActions from './actions';
import siteConfig from '@iso/config/site.config';
import { v4 as uuidV4 } from 'uuid';

async function uploadFiles(file) {
  const response = await fetch(`${siteConfig.apiUrl}/upload`, {
    crossDomain: true,
    method: 'POST',
    mode: 'cors',
    body: file,
  });
  return await response.json();
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

async function getUploadedImageUrl(url) {
  if (!url.startsWith('data:')) {
    return url;
  }
  const blob = dataURItoBlob(url);
  let ext = '';
  switch (blob.type) {
    case 'image/png':
      ext = '.png';
      break;
    case 'image/jpg':
      ext = '.jpg';
      break;
    case 'image/jpeg':
      ext = '.jpeg';
      break;
  }
  const formData = new FormData();
  formData.append('menus', blob, uuidV4() + ext);
  console.log('uploadFiles', blob);
  return uploadFiles(formData).then((result) => result.fileLinks[0]);
}

export const onMenuRequest = async (brandId) =>
  await fetch(`${siteConfig.apiUrl}/getMenus/` + brandId)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onCategoriesRequest = async () =>
  await fetch(`${siteConfig.apiUrl}/getCategories/`)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onAddProduct = async (brandId, newProduct) => {
  console.log('newProduct', newProduct);
  newProduct = { ...newProduct };
  if (newProduct.imageUrl)
    newProduct.imageUrl = await getUploadedImageUrl(newProduct.imageUrl);
  return await fetch(`${siteConfig.apiUrl}/product/` + brandId, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(newProduct),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onEditProduct = async (brandId, editProduct) => {
  editProduct = { ...editProduct };
  if (editProduct.imageUrl)
    editProduct.imageUrl = await getUploadedImageUrl(editProduct.imageUrl);

  return await fetch(`${siteConfig.apiUrl}/product/` + editProduct.id, {
    crossDomain: true,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(editProduct),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onDeleteProduct = async (uuid) =>
  await fetch(`${siteConfig.apiUrl}/deleteProduct/` + uuid, {
    crossDomain: true,
    method: 'DELETE',
    // headers: {
    //   'Content-Type': 'application/json',
    //   'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    // },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onRemoveProduct = async (brandId, productId) =>
  await fetch(`${siteConfig.apiUrl}/stopLists/${brandId}/${productId}`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onAddCategory = async (menuId, categoryId) => {
  return await fetch(`${siteConfig.apiUrl}/category`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({ menuId, categoryId }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onCreateCategory = async (menuId, category) => {
  return await fetch(`${siteConfig.apiUrl}/createCategory/${menuId}`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(category),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onEditCategory = async (category) => {
  return await fetch(`${siteConfig.apiUrl}/category`, {
    crossDomain: true,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(category),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onDeleteCategory = async (menuId, categoryId) => {
  return await fetch(`${siteConfig.apiUrl}/category`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({ menuId, categoryId }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

function* fetchMenuDataEffect({ brandId }) {
  try {
    let StopLists = yield call(onMenuRequest, brandId);
    yield put(menuActions.fetchMenuDataSuccess(StopLists));
  } catch (error) {
    yield put(menuActions.fetchMenuDataFailure(error));
  }
}

function* fetchCategoriesDataEffect() {
  try {
    let categories = yield call(onCategoriesRequest);
    yield put(menuActions.fetchCategoriesDataSuccess(categories));
  } catch (error) {
    yield put(menuActions.fetchCategoriesDataFailure(error));
  }
}

function* addProduct({ brandId, payload }) {
  try {
    let newProduct = yield call(onAddProduct, brandId, payload);
    yield put(menuActions.addProductSuccess(newProduct));
  } catch (error) {
    yield put(menuActions.addProductFailure(error));
  }
}

function* editProduct({ brandId, payload }) {
  try {
    let newProduct = yield call(onEditProduct, brandId, payload);

    yield put(menuActions.editProductSuccess(newProduct));
  } catch (error) {
    yield put(menuActions.editProductFailure(error));
  }
}

function* deleteProduct({ payload }) {
  try {
    let deletedProduct = yield call(onDeleteProduct, payload.uuid);

    yield put(menuActions.deleteProductSuccess(payload.id));
  } catch (error) {
    yield put(menuActions.deleteProductFailure(error));
  }
}

function* addCategory({ menuId, categoryId }) {
  try {
    yield call(onAddCategory, menuId, categoryId);

    yield put(menuActions.addCategorySuccess());
  } catch (error) {
    yield put(menuActions.addCategoryFailure(error));
  }
}
function* createCategory({ menuId, category }) {
  try {
    const newCategory = yield call(onCreateCategory, menuId, category);
    console.log(newCategory);

    yield put(menuActions.createCategorySuccess(newCategory));
  } catch (error) {
    yield put(menuActions.createCategoryFailure(error));
  }
}
function* editCategory({ category }) {
  try {
    let editCategory = yield call(onEditCategory, category);

    yield put(menuActions.editCategorySuccess(editCategory));
  } catch (error) {
    yield put(menuActions.editCategoryFailure(error));
  }
}
function* deleteCategory({ menuId, categoryId }) {
  try {
    yield call(onDeleteCategory, menuId, categoryId);

    yield put(menuActions.deleteCategorySuccess(categoryId));
  } catch (error) {
    yield put(menuActions.deleteCategoryFailure(error));
  }
}

const onWlUrlRequest = async (brandId, restaurantId) =>
  await fetch(
    `${siteConfig.apiUrl}/wlurl?id_restaurant=${restaurantId}&id_brand=${brandId}`
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchWlUrlStart({ payload }) {
  try {
    let wlurl = yield call(
      onWlUrlRequest,
      payload.brandId,
      payload.restaurantId
    );
    yield put(menuActions.fetchWlUrlSuccess(wlurl));
  } catch (error) {
    yield put(menuActions.fetchWlUrlFailure(error));
  }
}

const onPOSExportStatusRequest = async (brandId, restaurantId) =>
  await fetch(
    `${siteConfig.apiUrl}/rkImportData?id_restaurant=${restaurantId}&id_brand=${brandId}`
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchPOSExportStatusStart({ payload }) {
  try {
    let posData = yield call(
      onPOSExportStatusRequest,
      payload.brandId,
      payload.restaurantId
    );
    yield put(menuActions.fetchPOSExportStatusSuccess(posData));
  } catch (error) {
    yield put(menuActions.fetchPOSExportStatusFailure(error));
  }
}

const onPOSExportRequest = async (brandId, restaurantId) => {
  return await fetch(
    `${siteConfig.apiUrl}/rkExportMenu?id_restaurant=${restaurantId}&id_brand=${brandId}`,
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

function* fetchPOSExportStart({ payload }) {
  try {
    let posData = yield call(
      onPOSExportRequest,
      payload.brandId,
      payload.restaurantId
    );
    yield put(menuActions.fetchPOSExportSuccess(posData));
  } catch (error) {
    yield put(menuActions.fetchPOSExportFailure(error));
  }
}

const onCreateQRRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/createwlqr`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* fetchCreateQRStart({ payload }) {
  try {
    yield call(onCreateQRRequest, payload);

    yield put(menuActions.fetchCreateWLSuccess());
  } catch (error) {
    yield put(menuActions.fetchCreateWLFailure(error));
  }
}

const onCreateWLRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/createwl`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* fetchCreateWLStart({ payload }) {
  try {
    yield call(onCreateWLRequest, payload);

    yield put(menuActions.fetchCreateWLSuccess());
  } catch (error) {
    yield put(menuActions.fetchCreateWLFailure(error));
  }
}

const onSetQrMenuPropertyRequest = async (menuId, state) => {
  const response = await fetch(
    `${siteConfig.apiUrl}/qrMenuProperty?id_menu=${menuId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        state: state,
      }),
      crossDomain: true,
    }
  );
  return response.json();
};

function* setQrMenuProperty({ payload }) {
  try {
    const { status, menuId } = payload;
    let res = yield call(onSetQrMenuPropertyRequest, menuId, status);

    yield put(menuActions.setQrMenuPropertySuccess(res));
  } catch (error) {
    yield put(menuActions.setQrMenuPropertyFailure(error));
  }
}

export default function* menuSaga() {
  yield all([
    takeEvery(menuActions.FETCH_MENU_DATA_START, fetchMenuDataEffect),
    takeEvery(
      menuActions.FETCH_CATEGORIES_DATA_START,
      fetchCategoriesDataEffect
    ),
    takeEvery(menuActions.ADD_PRODUCT, addProduct),
    takeEvery(menuActions.EDIT_PRODUCT, editProduct),
    takeEvery(menuActions.DELETE_PRODUCT, deleteProduct),
    takeEvery(menuActions.ADD_CATEGORY, addCategory),
    takeEvery(menuActions.CREATE_CATEGORY, createCategory),
    takeEvery(menuActions.EDIT_CATEGORY, editCategory),
    takeEvery(menuActions.DELETE_CATEGORY, deleteCategory),
    takeEvery(menuActions.FETCH_WLURL_START, fetchWlUrlStart),
    takeEvery(
      menuActions.FETCH_POS_EXPORT_STATUS_START,
      fetchPOSExportStatusStart
    ),
    takeEvery(menuActions.FETCH_POS_EXPORT_START, fetchPOSExportStart),
    takeEvery(menuActions.FETCH_CREATE_QR_START, fetchCreateQRStart),
    takeEvery(menuActions.FETCH_CREATE_WL_START, fetchCreateWLStart),
    takeEvery(menuActions.SET_QR_MENU_PROPERTY, setQrMenuProperty),
  ]);
}
