import antdHy from 'antd/lib/locale-provider/hy_AM';
import enMessages from '../locales/hy-AM.json';

const saLang = {
  messages: {
    ...enMessages,
  },
  antd: antdHy,
  locale: 'hy-AM',
};
export default saLang;
