import siteConfig from '@iso/config/site.config';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import restaurantActions from './actions';

const onFetchRestaurants = async () =>
  await fetch(`${siteConfig.apiUrl}/brandRestaurant`, {
    crossDomain: true,
    method: 'GET',
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json.places || [];
    });

function* fetchGetRestaurants() {
  try {
    let restaurants = yield call(onFetchRestaurants);
    yield put(restaurantActions.fetchRestaurantsSuccess(restaurants));
  } catch (error) {
    yield put(restaurantActions.fetchRestaurantsFailure(error));
  }
}

export const onGetRestaurantState = async () =>
  await fetch(`${siteConfig.apiUrl}/`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onSetRestaurantBlock = async (data) =>
  await fetch(`${siteConfig.apiUrl}/block`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onSetRestaurantUnblock = async (data) =>
  await fetch(`${siteConfig.apiUrl}/unblock`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchGetRestaurantState({ payload }) {
  try {
    const { id } = payload;
    console.log('payload', id);
    let block = yield call(onGetRestaurantState, id);
    yield put(restaurantActions.setRestaurantBlockSuccess(block));
  } catch (error) {
    yield put(restaurantActions.setRestaurantBlockFailure(error));
  }
}

function* fetchSetRestaurantBlock({ payload }) {
  try {
    const { data } = payload;
    console.log('payload', data);
    let block = yield call(onSetRestaurantBlock, data);
    yield put(restaurantActions.setRestaurantBlockSuccess(block));
  } catch (error) {
    yield put(restaurantActions.setRestaurantBlockFailure(error));
  }
}

function* fetchSetRestaurantUnblock({ payload }) {
  try {
    const { data } = payload;
    console.log('payload', data);
    let unblock = yield call(onSetRestaurantUnblock, data);
    yield put(restaurantActions.setRestaurantUnblockSuccess(unblock));
  } catch (error) {
    yield put(restaurantActions.setRestaurantUnblockFailure(error));
  }
}

const onFetchRestaurantsWL = async () =>
  await fetch(`${siteConfig.apiUrl}/wlSites`, {
    crossDomain: true,
    method: 'GET',
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json.sites || [];
    });

function* fetchGetRestaurantsWL() {
  try {
    let restaurants = yield call(onFetchRestaurantsWL);
    yield put(restaurantActions.fetchRestaurantsWLSuccess(restaurants));
  } catch (error) {
    yield put(restaurantActions.fetchRestaurantsWLFailure(error));
  }
}

const onFetchRestaurantTables = async (restaurantId) =>
  await fetch(
    `${siteConfig.apiUrl}/restaurantTables?restaurant_id=${restaurantId}`,
    {
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json.tables || [];
    });

function* fetchGetRestaurantTables({ payload }) {
  try {
    let tables = yield call(onFetchRestaurantTables, payload);
    yield put(restaurantActions.fetchRestaurantTablesSuccess(tables));
  } catch (error) {
    yield put(restaurantActions.fetchRestaurantTablesFailure(error));
  }
}

const onFetchAddRestaurantTable = async (newTable) =>
  await fetch(`${siteConfig.apiUrl}/restaurantTable`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(newTable),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchAddRestaurantTable({ payload }) {
  try {
    let newTable = yield call(onFetchAddRestaurantTable, payload);
    yield put(restaurantActions.fetchAddRestaurantTableSuccess(newTable));
  } catch (error) {
    yield put(restaurantActions.fetchAddRestaurantTableFailure(error));
  }
}

const onFetchRestaurantWaiters = async (restaurantId) =>
  await fetch(
    `${siteConfig.apiUrl}/restaurantWaiters?restaurant_id=${restaurantId}`,
    {
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json.waiters || [];
    });

function* fetchGetRestaurantWaiters({ payload }) {
  try {
    let tables = yield call(onFetchRestaurantWaiters, payload);
    yield put(restaurantActions.fetchRestaurantWaitersSuccess(tables));
  } catch (error) {
    yield put(restaurantActions.fetchRestaurantWaitersFailure(error));
  }
}

const onFetchAddRestaurantWaiter = async (newWaiter) =>
  await fetch(`${siteConfig.apiUrl}/restaurantWaiter`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(newWaiter),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

const onFetchUpdateRestaurantWaiter = async (newWaiter) =>
  await fetch(`${siteConfig.apiUrl}/restaurantWaiter`, {
    crossDomain: true,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(newWaiter),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchAddRestaurantWaiter({ payload }) {
  try {
    let waiter;
    if (!payload.id) waiter = yield call(onFetchAddRestaurantWaiter, payload);
    else waiter = yield call(onFetchUpdateRestaurantWaiter, payload);
    yield put(restaurantActions.fetchAddRestaurantWaiterSuccess(waiter));
  } catch (error) {
    yield put(restaurantActions.fetchAddRestaurantWaiterFailure(error));
  }
}

const onFetchAddWaiterToTable = async (waiterId, tableId) =>
  await fetch(`${siteConfig.apiUrl}/addWaiterToTable`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({ waiter_id: waiterId, table_id: tableId }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchAddWaiterToTable({ payload }) {
  try {
    const { waiterId, tableId } = payload;
    let res = yield call(onFetchAddWaiterToTable, waiterId, tableId);
    if (res)
      yield put(
        restaurantActions.fetchAddWaiterToTableSuccess({ waiterId, tableId })
      );
    else yield put(restaurantActions.fetchAddWaiterToTableFailure('false'));
  } catch (error) {
    yield put(restaurantActions.fetchAddWaiterToTableFailure(error));
  }
}

const onFetchDeleteWaiterFromTable = async (waiterId, tableId) =>
  await fetch(`${siteConfig.apiUrl}/deleteWaiterTable`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({ waiter_id: waiterId, table_id: tableId }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchDeleteWaiterFromTable({ payload }) {
  try {
    const { waiterId, tableId } = payload;
    let res = yield call(onFetchDeleteWaiterFromTable, waiterId, tableId);
    if (res)
      yield put(
        restaurantActions.fetchDeleteWaiterFromTableSuccess({
          waiterId,
          tableId,
        })
      );
    else
      yield put(restaurantActions.fetchDeleteWaiterFromTableFailure('false'));
  } catch (error) {
    yield put(restaurantActions.fetchDeleteWaiterFromTableFailure(error));
  }
}

const onFetchDeleteRestaurantTable = async (tableId) =>
  await fetch(`${siteConfig.apiUrl}/restaurantTable?table_id=${tableId}`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchDeleteRestaurantTable({ payload }) {
  try {
    const { tableId } = payload;
    let res = yield call(onFetchDeleteRestaurantTable, tableId);
    if (res)
      yield put(
        restaurantActions.fetchDeleteRestaurantTableSuccess({ tableId })
      );
    else
      yield put(
        restaurantActions.fetchDeleteRestaurantTableFailure('table not deleted')
      );
  } catch (error) {
    yield put(restaurantActions.fetchDeleteRestaurantTableFailure(error));
  }
}

const onFetchDeleteRestaurantWaiter = async (waiterId) =>
  await fetch(`${siteConfig.apiUrl}/restaurantWaiter?waiter_id=${waiterId}`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchDeleteRestaurantWaiter({ payload }) {
  try {
    const { waiterId } = payload;
    let res = yield call(onFetchDeleteRestaurantWaiter, waiterId);
    if (res)
      yield put(
        restaurantActions.fetchDeleteRestaurantWaiterSuccess({ waiterId })
      );
    else
      yield put(
        restaurantActions.fetchDeleteRestaurantWaiterFailure(
          'waiter not deleted'
        )
      );
  } catch (error) {
    yield put(restaurantActions.fetchDeleteRestaurantWaiterFailure(error));
  }
}

const onFetchRestaurantRating = async (restaurantId) =>
  await fetch(
    `${siteConfig.apiUrl}/qrOrderRating?restaurant_id=${restaurantId}`,
    {
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json.ratings || [];
    });

function* fetchGetRestaurantRating({ payload }) {
  try {
    let ratings = yield call(onFetchRestaurantRating, payload);
    yield put(restaurantActions.fetchRestaurantRatingSuccess(ratings));
  } catch (error) {
    yield put(restaurantActions.fetchRestaurantRatingFailure(error));
  }
}

const onFetchRestaurantManagers = async (restaurantId) =>
  await fetch(
    `${siteConfig.apiUrl}/restaurantManagers?restaurant_id=${restaurantId}`,
    {
      crossDomain: true,
      method: 'GET',
      mode: 'cors',
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json.managers || [];
    });

function* fetchGetRestaurantManagers({ payload }) {
  try {
    let tables = yield call(onFetchRestaurantManagers, payload);
    yield put(restaurantActions.fetchRestaurantManagersSuccess(tables));
  } catch (error) {
    yield put(restaurantActions.fetchRestaurantManagersFailure(error));
  }
}

const onFetchAddRestaurantManager = async (newManager) =>
  await fetch(`${siteConfig.apiUrl}/restaurantManager`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(newManager),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

const onFetchUpdateRestaurantManager = async (newManager) =>
  await fetch(`${siteConfig.apiUrl}/restaurantManager`, {
    crossDomain: true,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(newManager),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchAddRestaurantManager({ payload }) {
  try {
    let manager;
    if (!payload.id) manager = yield call(onFetchAddRestaurantManager, payload);
    else manager = yield call(onFetchUpdateRestaurantManager, payload);
    yield put(restaurantActions.fetchAddRestaurantManagerSuccess(manager));
  } catch (error) {
    yield put(restaurantActions.fetchAddRestaurantManagerFailure(error));
  }
}

const onFetchAddManagerToTable = async (managerId, tableId) =>
  await fetch(`${siteConfig.apiUrl}/addManagerToTable`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({ manager_id: managerId, table_id: tableId }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchAddManagerToTable({ payload }) {
  try {
    const { managerId, tableId } = payload;
    let res = yield call(onFetchAddManagerToTable, managerId, tableId);
    if (res)
      yield put(
        restaurantActions.fetchAddManagerToTableSuccess({ managerId, tableId })
      );
    else yield put(restaurantActions.fetchAddManagerToTableFailure('false'));
  } catch (error) {
    yield put(restaurantActions.fetchAddManagerToTableFailure(error));
  }
}

const onFetchDeleteManagerFromTable = async (managerId, tableId) =>
  await fetch(`${siteConfig.apiUrl}/deleteManagerTable`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({ manager_id: managerId, table_id: tableId }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchDeleteManagerFromTable({ payload }) {
  try {
    const { managerId, tableId } = payload;
    let res = yield call(onFetchDeleteManagerFromTable, managerId, tableId);
    if (res)
      yield put(
        restaurantActions.fetchDeleteManagerFromTableSuccess({
          managerId,
          tableId,
        })
      );
    else
      yield put(restaurantActions.fetchDeleteManagerFromTableFailure('false'));
  } catch (error) {
    yield put(restaurantActions.fetchDeleteManagerFromTableFailure(error));
  }
}

const onFetchDeleteRestaurantManager = async (managerId) =>
  await fetch(
    `${siteConfig.apiUrl}/restaurantManager?manager_id=${managerId}`,
    {
      crossDomain: true,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchDeleteRestaurantManager({ payload }) {
  try {
    const { managerId } = payload;
    let res = yield call(onFetchDeleteRestaurantManager, managerId);
    if (res)
      yield put(
        restaurantActions.fetchDeleteRestaurantManagerSuccess({ managerId })
      );
    else
      yield put(
        restaurantActions.fetchDeleteRestaurantManagerFailure(
          'table not deleted'
        )
      );
  } catch (error) {
    yield put(restaurantActions.fetchDeleteRestaurantManagerFailure(error));
  }
}

export default function* restaurantSaga() {
  yield all([
    takeEvery(restaurantActions.FETCH_RESTAURANTS_START, fetchGetRestaurants),
    takeEvery(restaurantActions.GET_RESTAURANT_STATE, fetchGetRestaurantState),
    takeEvery(restaurantActions.SET_RESTAURANT_BLOCK, fetchSetRestaurantBlock),
    takeEvery(
      restaurantActions.SET_RESTAURANT_UNBLOCK,
      fetchSetRestaurantUnblock
    ),
    takeEvery(
      restaurantActions.FETCH_RESTAURANTS_WL_START,
      fetchGetRestaurantsWL
    ),
    takeEvery(
      restaurantActions.FETCH_RESTAURANT_TABLES_START,
      fetchGetRestaurantTables
    ),
    takeEvery(
      restaurantActions.FETCH_ADD_RESTAURANT_TABLE_START,
      fetchAddRestaurantTable
    ),
    takeEvery(
      restaurantActions.FETCH_RESTAURANT_WAITERS_START,
      fetchGetRestaurantWaiters
    ),
    takeEvery(
      restaurantActions.FETCH_ADD_RESTAURANT_WAITER_START,
      fetchAddRestaurantWaiter
    ),

    takeEvery(
      restaurantActions.FETCH_ADD_WAITER_TO_TABLE_START,
      fetchAddWaiterToTable
    ),
    takeEvery(
      restaurantActions.FETCH_DELETE_WAITER_FROM_TABLE_START,
      fetchDeleteWaiterFromTable
    ),
    takeEvery(
      restaurantActions.FETCH_DELETE_RESTAURANT_TABLE_START,
      fetchDeleteRestaurantTable
    ),
    takeEvery(
      restaurantActions.FETCH_DELETE_RESTAURANT_WAITER_START,
      fetchDeleteRestaurantWaiter
    ),
    takeEvery(
      restaurantActions.FETCH_RESTAURANT_RATING_START,
      fetchGetRestaurantRating
    ),
    takeEvery(
      restaurantActions.FETCH_RESTAURANT_MANAGERS_START,
      fetchGetRestaurantManagers
    ),
    takeEvery(
      restaurantActions.FETCH_ADD_RESTAURANT_MANAGERS_START,
      fetchAddRestaurantManager
    ),
    takeEvery(
      restaurantActions.FETCH_ADD_MANAGER_TO_TABLE_START,
      fetchAddManagerToTable
    ),
    takeEvery(
      restaurantActions.FETCH_DELETE_MANAGER_FROM_TABLE_START,
      fetchDeleteManagerFromTable
    ),
    takeEvery(
      restaurantActions.FETCH_DELETE_RESTAURANT_MANAGER_START,
      fetchDeleteRestaurantManager
    ),
  ]);
}
