const StopLists = {
  lastUpdatedAt: '2021-05-27T11:08:18+0300',
  menuItems: {
    categories: [
      {
        id: '1',
        parentId: null,
        name: 'САЛАТЫ',
      },
      {
        id: '2',
        parentId: null,
        name: 'СУПЫ',
      },
      {
        id: '3',
        parentId: null,
        name: 'ОСНОВНЫЕ БЛЮДА',
      },
      {
        id: '4',
        parentId: null,
        name: 'ЗАКУСКИ (ЭКСТРА)',
      },
      {
        id: '5',
        parentId: null,
        name: 'ДЕСЕРТЫ',
      },
      {
        id: '6',
        parentId: null,
        name: 'СОУСЫ',
      },
      {
        id: '7',
        parentId: null,
        name: 'НАПИТКИ',
      },
    ],
    products: [
      {
        id: '1',
        categoryId: '1',
        name: 'Пряный азиатский салат',
        description:
          'Аппетитные хрустящие огурцы в азиатском маринаде из кинзы, чили и кунжута. Состав: огурцы, кунжутные семечки, перец чили, соевый соус, кунжутное масло, чеснок, лимонный сок.',
        price: 150,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/AziatsSalat.png',
        byWeight: false,
        weight: '150',
      },
      {
        id: '2',
        categoryId: '1',
        name: 'Табуле',
        description:
          'Традиционный ближневосточный салат из булгура и томатов, щедро приправленный оливковым маслом и смесью душистых специй. Состав: булгур, томаты, зеленый лук, кинза, мята, петрушка, специи.',
        price: 220,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/CoulSoul.png',
        byWeight: false,
        weight: '150',
      },
      {
        id: '3',
        categoryId: '1',
        name: 'Коул слоу',
        description:
          'Традиционный американский салат, идеальный гарнир к мясу. Два вида мелко нашинкованной капусты заправлены пикантным сливочным соусом. Состав: капуста красная, капуста белокочанная, морковь,\r\nяблоко, дижонская горчица, яблочный уксус, соль, перец.',
        price: 150,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Tabule.png',
        byWeight: false,
        weight: '150',
      },
      {
        id: '4',
        categoryId: '2',
        name: 'Восточный суп с булгуром',
        description:
          'Сытный и полезный, он подарит вам бодрость на целый день. В составе — только натуральные ингредиенты растительного происхождения; булгур, пшеничная крупа, чечевица. Состав: булгур, крупа пшеничная, овощи сушеные (репчатый\r\nлук, томаты), чечевица, специи молотые (черный перец,\r\nкуркума).',
        price: 260,
        vat: 0,
        imageUrl:
          'http://orderbyte.io/api/v1/download/menus/Sup129s8sBul9gurom.png',
        byWeight: false,
        weight: '350',
      },
      {
        id: '5',
        categoryId: '2',
        name: 'Чечевичный тибетский суп',
        description:
          'Пряный индийский суп, приготовленный по классическому рецепту из чечевицы с добавлением ароматных специй. Состав: чечевица, сушеные овощи и зелень (репчатый лук,\r\nтоматы, петрушка, чеснок), молотые специи (кумин, перец\r\nчили, куркума).',
        price: 290,
        vat: 0,
        imageUrl:
          'http://orderbyte.io/api/v1/download/menus/SupChechevitchin_1231231_213.png',
        byWeight: false,
        weight: '350',
      },
      {
        id: '6',
        categoryId: '3',
        name: 'Крылышки Ковбойский BBQ',
        description:
          'Классические куриные крылышки в американском стиле. Мы тщательно маринуем их в домашнем соусе BBQ и обжариваем в кипящем масле до хрустящей корочки. Состав: крылья глазированные, домашний соус BBQ.',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/BBQ.png',
        byWeight: false,
        weight: '340',
      },
      {
        id: '7',
        categoryId: '3',
        name: 'Острые крылышки: Burn, MF**, Burn!',
        description:
          'Warning! Эти крылышки созданы для настоящих любителей острого, при приготовлении маринада и соуса наш шеф-повар не жалел перца. Трех перцев, если быть честным: кайенского, халапеньо и чили. Состав: крылья, глазированные в остром соусе из трех\r\nперцев: кайенского, халапеньо и чили.',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Sharp.png',
        byWeight: false,
        weight: '340',
      },
      {
        id: '8',
        categoryId: '3',
        name: 'Крылышки Токийский Терияки',
        description:
          'Аутентичные крылья «Терияки», глазированные домашним маринадом на основе имбиря, тростникового сахара, винного уксуса, кунжута и соевого соуса. Щедро приправлены кунжутом. Состав: крылья глазированные, домашний соус «Терияки»,\r\nкунжут.',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Teriyaki.png',
        byWeight: false,
        weight: '340',
      },
      {
        id: '9',
        categoryId: '3',
        name: 'Крылышки Карри из Мумбаи',
        description:
          'Мы одолжили рецепт пикантного соуса карри у индийских поваров. Характерный вкус и аромат ему достался от смеси восточных специй — карри, куркумы, зиры, имбирного корня, аниса и острого перца. Состав: крылья в хрустящей панировке, соус карри.',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Curry.png',
        byWeight: false,
        weight: '500',
      },
      {
        id: '10',
        categoryId: '3',
        name: 'Крылышки Sweet Chili из Бангкока',
        description:
          'Секрет популярности «Sweet Chili из Бангкока» — в нашем фирменном маринаде. Прежде чем попасть на ваш стол, отборные крылышки отдыхают в соусе из сладкого перца чили. Подаются с ароматной кинзой. Состав: крылья глазированные, домашний соус Sweet Chili,\r\nкинза.',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/SweetChili.png',
        byWeight: false,
        weight: '340',
      },
      {
        id: '11',
        categoryId: '3',
        name: 'Медово-горчичные крылья из Канады',
        description:
          'Гордость нашей коллекции, куриные крылышки в домашнем медово-горчичном соусе. Мы готовим его из двух видов горчицы с добавлением горного меда. Обжарены в кипящем масле до аппетитной корочки. Состав: крылья в хрустящей панировке, соус медово-\r\nгорчичный.',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Mustard.png',
        byWeight: false,
        weight: '470',
      },
      {
        id: '12',
        categoryId: '3',
        name: 'Шанхайские крылышки Sweet Cola',
        description:
          'Крылышки, замаринованные в «Кока-коле»? Да, именно так их принято готовить в Шанхае. В результате крылья приобретают необычный, но очень привлекательный вкус. Попробуйте, и вы влюбитесь в них с первого кусочка. Состав: крылья глазированные, соус Sweet Cola (Кока-кола,\r\nсоевый соус, картофельный крахмал).',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Cola.png',
        byWeight: false,
        weight: '340',
      },
      {
        id: '13',
        categoryId: '3',
        name: 'Легендарные крылышки Buffalo',
        description:
          'Нестареющая классика, оригинальные крылышки Buffalo. Мы готовим по каноническому рецепту, не жалеем меда и щедро приправляем тремя видами перца. Состав: крылья глазированные, соус «Buffalo» (сливочное\r\nмасло, кетчуп, мед, чеснок, соль, перец черный, перец\r\nкайенский, соус шрирача).',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Buffalo.png',
        byWeight: false,
        weight: '340',
      },
      {
        id: '14',
        categoryId: '3',
        name: 'Паназиатские крылышки Sweet & Sour',
        description:
          'По-настоящему азиатский вкус этим крылышкам придает глазурь на основе нашего фирменного кисло-сладкого соуса. В ней есть всё, что нужно для счастья — мед, соевый соус, кетчуп и кунжутное масло. Состав: крылья глазированные, кисло-сладкий соус (кетчуп,\r\nмед, соевый соус, кунжутное масло, паприка сладкая\r\nмолотая).',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/SourSweet.png',
        byWeight: false,
        weight: '340',
      },
      {
        id: '15',
        categoryId: '3',
        name: 'Крылышки Том Ям из Сайгона',
        description:
          'Добро пожаловать в Сайгон! Только там (а еще и в Holy Wings) можно попробовать экзотические крылышки «Том Ям». Мы маринуем их в необычном соусе из пасты том ям и сгущенного молока, а потом обжариваем до аппетитной корочки. Состав: крылья в хрустящей панировке, домашний соус «Том\r\nЯм» (паста том ям, майонез, сгущенное молоко).',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/TomYam.png',
        byWeight: false,
        weight: '340',
      },
      {
        id: '16',
        categoryId: '3',
        name: 'Крылышки Выбор Махараджи',
        description:
          'Пряные и ароматные крылышки в соусе «Тандури Масала» перенесут вас в Индию. Мы готовим их, используя маринад на основе смеси восточных специй; тмина, кориандра, имбиря и корицы. Остается лишь деликатно их обжарить и подать к столу. Теперь вам позавидуют даже магараджи! Состав: крылья в хрустящей панировке, соус «Тандури»\r\n(майонез, специи тандури масала).',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Tandoori.png',
        byWeight: false,
        weight: '500',
      },
      {
        id: '17',
        categoryId: '3',
        name: 'Мадридские крылышки Айоли',
        description:
          'Hola, Madrid! Эти крылышки приготовлены с истинно испанской страстью, хорошо промаринованы в соусе айоли и обжарены до хрустящей корочки. Состав: крылья в хрустящей панировке, соус Aioli (сырный соус, чеснок,\r\nсметана, соус Red Devil, перец кайенский, перец черный, хмели-сунели).',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Wives.png',
        byWeight: false,
        weight: '500',
      },
      {
        id: '18',
        categoryId: '3',
        name: 'Лимонные калифорнийские крылышки',
        description:
          'Любимые крылышки сёрферов маринуются в легком цитрусовом соусе с добавлением душистой лимонной цедры. Пару минут в кипящем масле, — и гордость Калифорнии готова. Состав:\r\nкрылья в хрустящей панировке, домашний\r\nцитрусовый соус (сливочный соус, лимонный сок, цедра\r\nлимона, сахар).',
        price: 490,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Lemon.png',
        byWeight: false,
        weight: '500',
      },
      {
        id: '19',
        categoryId: '4',
        name: 'Картофель фри с сырным соусом',
        description:
          'Классический хрустящий картофель фри с эталонным сырным соусом. Состав: картофель, соль, сырный соус.',
        price: 250,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/FrenchFries.png',
        byWeight: false,
        weight: '150',
      },
      {
        id: '20',
        categoryId: '4',
        name: 'Луковые кольца',
        description:
          'Обжаренные во фритюре луковые кольца с пряными специями. Состав: репчатый лук, мука, яйцо, разрыхлитель теста,\r\nмолоко, панировочные сухари, соль.',
        price: 250,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Rings.png',
        byWeight: false,
        weight: '150',
      },
      {
        id: '21',
        categoryId: '4',
        name: 'Куриные стрипсы',
        description:
          'Классическая американская закуска: куриное филе в панировке. Состав: куриное филе, кляр (пшеничная мука, молоко, вода,\r\nкуриные яйца, разрыхлитель теста, соль) панировка (рисовая\r\nмука, пшеничная мука, паприка молотая, соль).',
        price: 400,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Stripsy2.png',
        byWeight: false,
        weight: '320',
      },
      {
        id: '22',
        categoryId: '5',
        name: 'Чуррос с воздушным шоколадом',
        description:
          'Знаменитый испанский десерт: выпечка из заварного теста. Доставляется с шоколадным соусом. Состав:\r\nчуррос, шоколадный топпинг, сахарная пудра,\r\nкорица.',
        price: 350,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/Churros.png',
        byWeight: false,
        weight: '180',
      },
      {
        id: '23',
        categoryId: '6',
        name: 'BBQ',
        description: 'Соус',
        price: 25,
        vat: 0,
        imageUrl: '',
        byWeight: false,
        weight: '50',
      },
      {
        id: '24',
        categoryId: '6',
        name: 'Терияки',
        description: 'Соус',
        price: 25,
        vat: 0,
        imageUrl: '',
        byWeight: false,
        weight: '50',
      },
      {
        id: '25',
        categoryId: '6',
        name: 'Sweet Chili',
        description: 'Соус',
        price: 25,
        vat: 0,
        imageUrl: '',
        byWeight: false,
        weight: '50',
      },
      {
        id: '26',
        categoryId: '6',
        name: 'Том Ям',
        description: 'Соус',
        price: 25,
        vat: 0,
        imageUrl: '',
        byWeight: false,
        weight: '50',
      },
      {
        id: '27',
        categoryId: '6',
        name: 'Тандури',
        description: 'Соус',
        price: 25,
        vat: 0,
        imageUrl: '',
        byWeight: false,
        weight: '50',
      },
      {
        id: '28',
        categoryId: '6',
        name: 'Медово-горчичный',
        description: 'Соус',
        price: 25,
        vat: 0,
        imageUrl: '',
        byWeight: false,
        weight: '50',
      },
      {
        id: '29',
        categoryId: '6',
        name: 'Карри',
        description: 'Соус',
        price: 25,
        vat: 0,
        imageUrl: '',
        byWeight: false,
        weight: '50',
      },
      {
        id: '30',
        categoryId: '6',
        name: 'Цитрусовый',
        description: 'Соус',
        price: 25,
        vat: 0,
        imageUrl: '',
        byWeight: false,
        weight: '50',
      },
      {
        id: '31',
        categoryId: '7',
        name: 'КОКА-КОЛА (0,33 мл, ж/б)',
        description: 'Напиток',
        price: 33,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/coca-cola033.jpg',
        byWeight: false,
        volume: '330',
      },
      {
        id: '32',
        categoryId: '7',
        name: 'КОКА-КОЛА ЗЕРО (0,33 мл, ж/б)',
        description: 'Напиток',
        price: 33,
        vat: 0,
        imageUrl:
          'http://orderbyte.io/api/v1/download/menus/cocacolazer033.jpg',
        byWeight: false,
        volume: '330',
      },
      {
        id: '33',
        categoryId: '7',
        name: 'ФАНТА (0,33 мл, ж/б)',
        description: 'Напиток',
        price: 33,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/fanta033.jpg',
        byWeight: false,
        volume: '330',
      },
      {
        id: '34',
        categoryId: '7',
        name: 'СПРАЙТ (0,33 мл, ж/б) ',
        description: 'Напиток',
        price: 33,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/sprite033.jpg',
        byWeight: false,
        volume: '330',
      },
      {
        id: '35',
        categoryId: '7',
        name: 'ХОЛОДНЫЙ ЧАЙ «ЛИМОН-ЛЕМОНГРАС» (0,33 мл, ж/б) ',
        description: 'Напиток',
        price: 33,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/lemongrass033.jpg',
        byWeight: false,
        volume: '330',
      },
      {
        id: '36',
        categoryId: '7',
        name: 'МИНЕРАЛЬНАЯ ВОДА «БОН АКВА» С ГАЗОМ (0,5 мл, пластик)',
        description: 'Напиток',
        price: 50,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/bonaquagaz05.jpg',
        byWeight: false,
        volume: '500',
      },
      {
        id: '37',
        categoryId: '7',
        name: 'МИНЕРАЛЬНАЯ ВОДА «БОН АКВА» БЕЗ ГАЗА (0,5 мл, пластик)',
        description: 'Напиток',
        price: 50,
        vat: 0,
        imageUrl: 'http://orderbyte.io/api/v1/download/menus/bonaqua05.jpg',
        byWeight: false,
        volume: '500',
      },
    ],
  },
};
export default StopLists;
