const menuActions = {
  FETCH_MENU_DATA_START: 'FETCH_MENU_DATA_START',
  FETCH_MENU_DATA_SUCCESS: 'FETCH_MENU_DATA_SUCCESS',
  FETCH_MENU_DATA_FAILURE: 'FETCH_MENU_DATA_FAILURE',
  FETCH_CATEGORIES_DATA_START: 'FETCH_CATEGORIES_DATA_START',
  FETCH_CATEGORIES_DATA_SUCCESS: 'FETCH_CATEGORIES_DATA_SUCCESS',
  FETCH_CATEGORIES_DATA_FAILURE: 'FETCH_CATEGORIES_DATA_FAILURE',
  SET_MENU_DATA: 'SET_MENU_DATA',
  SET_PRODUCT: 'SET_PRODUCT',
  ADD_PRODUCT: 'ADD_PRODUCT',
  ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_FAILURE: 'ADD_PRODUCT_FAILURE',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  EDIT_PRODUCT_SUCCESS: 'EDIT_PRODUCT_SUCCESS',
  EDIT_PRODUCT_FAILURE: 'EDIT_PRODUCT_FAILURE',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',
  ADD_CATEGORY: 'ADD_CATEGORY',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
  ADD_CATEGORY_FAILURE: 'ADD_CATEGORY_FAILURE',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
  EDIT_CATEGORY_FAILURE: 'EDIT_CATEGORY_FAILURE',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

  FETCH_WLURL_START: 'FETCH_WLURL_START',
  FETCH_WLURL_SUCCESS: 'FETCH_WLURL_SUCCESS',
  FETCH_WLURL_FAILURE: 'FETCH_WLURL_FAILURE',

  FETCH_POS_EXPORT_STATUS_START: 'FETCH_POS_EXPORT_STATUS_START',
  FETCH_POS_EXPORT_STATUS_SUCCESS: 'FETCH_POS_EXPORT_STATUS_SUCCESS',
  FETCH_POS_EXPORT_STATUS_FAILURE: 'FETCH_POS_EXPORT_STATUS_FAILURE',

  FETCH_POS_EXPORT_START: 'FETCH_POS_EXPORT_START',
  FETCH_POS_EXPORT_SUCCESS: 'FETCH_POS_EXPORT_SUCCESS',
  FETCH_POS_EXPORT_FAILURE: 'FETCH_POS_EXPORT_FAILURE',

  FETCH_CREATE_QR_START: 'FETCH_CREATE_QR_START',
  FETCH_CREATE_WL_START: 'FETCH_CREATE_WL_START',
  FETCH_CREATE_WL_SUCCESS: 'FETCH_CREATE_WL_SUCCESS',
  FETCH_CREATE_WL_FAILURE: 'FETCH_CREATE_WL_FAILURE',

  SET_QR_MENU_PROPERTY: 'SET_QR_MENU_PROPERTY',
  SET_QR_MENU_PROPERTY_SUCCESS: 'SET_QR_MENU_PROPERTY_SUCCESS',
  SET_QR_MENU_PROPERTY_FAILURE: 'SET_QR_MENU_PROPERTY_FAILURE',

  fetchMenuDataStart: (brandId) => ({
    type: menuActions.FETCH_MENU_DATA_START,
    brandId,
  }),
  fetchMenuDataSuccess: (menu) => ({
    type: menuActions.FETCH_MENU_DATA_SUCCESS,
    payload: menu,
  }),
  fetchMenuDataFailure: (error) => ({
    type: menuActions.FETCH_MENU_DATA_FAILURE,
    payload: error,
  }),

  fetchCategoriesDataStart: () => ({
    type: menuActions.FETCH_CATEGORIES_DATA_START,
  }),
  fetchCategoriesDataSuccess: (menu) => ({
    type: menuActions.FETCH_CATEGORIES_DATA_SUCCESS,
    payload: menu,
  }),
  fetchCategoriesDataFailure: (error) => ({
    type: menuActions.FETCH_CATEGORIES_DATA_FAILURE,
    payload: error,
  }),

  addProduct: (brandId, product) => ({
    type: menuActions.ADD_PRODUCT,
    payload: product,
    brandId,
  }),
  addProductSuccess: (product) => ({
    type: menuActions.ADD_PRODUCT_SUCCESS,
    payload: product,
  }),
  addProductFailure: (error) => ({
    type: menuActions.ADD_PRODUCT_FAILURE,
    payload: error,
  }),

  editProduct: (brandId, product) => ({
    type: menuActions.EDIT_PRODUCT,
    payload: product,
    brandId,
  }),
  editProductSuccess: (product) => ({
    type: menuActions.EDIT_PRODUCT_SUCCESS,
    payload: product,
  }),
  editProductFailure: (error) => ({
    type: menuActions.EDIT_PRODUCT_FAILURE,
    payload: error,
  }),

  deleteProduct: ({ id, uuid }) => ({
    type: menuActions.DELETE_PRODUCT,
    payload: { id, uuid },
  }),
  deleteProductSuccess: (product) => ({
    type: menuActions.DELETE_PRODUCT_SUCCESS,
    payload: product,
  }),
  deleteProductFailure: (error) => ({
    type: menuActions.DELETE_PRODUCT_FAILURE,
    payload: error,
  }),

  addCategory: (menuId, categoryId) => ({
    type: menuActions.ADD_CATEGORY,
    menuId,
    categoryId,
  }),
  addCategorySuccess: (product) => ({
    type: menuActions.ADD_CATEGORY_SUCCESS,
    payload: product,
  }),
  addCategoryFailure: (error) => ({
    type: menuActions.ADD_CATEGORY_FAILURE,
    payload: error,
  }),

  createCategory: (menuId, category) => ({
    type: menuActions.CREATE_CATEGORY,
    menuId,
    category: category,
  }),
  createCategorySuccess: (product) => ({
    type: menuActions.CREATE_CATEGORY_SUCCESS,
    payload: product,
  }),
  createCategoryFailure: (error) => ({
    type: menuActions.CREATE_CATEGORY_FAILURE,
    payload: error,
  }),

  editCategory: (category) => ({
    type: menuActions.EDIT_CATEGORY,
    category: category,
  }),
  editCategorySuccess: (product) => ({
    type: menuActions.EDIT_CATEGORY_SUCCESS,
    payload: product,
  }),
  editCategoryFailure: (error) => ({
    type: menuActions.EDIT_CATEGORY_FAILURE,
    payload: error,
  }),

  deleteCategory: (menuId, categoryId) => ({
    type: menuActions.DELETE_CATEGORY,
    menuId,
    categoryId,
  }),
  deleteCategorySuccess: (product) => ({
    type: menuActions.DELETE_CATEGORY_SUCCESS,
    payload: product,
  }),
  deleteCategoryFailure: (error) => ({
    type: menuActions.DELETE_CATEGORY_FAILURE,
    payload: error,
  }),

  fetchWlUrlStart: (brandId, restaurantId) => ({
    type: menuActions.FETCH_WLURL_START,
    payload: {
      brandId,
      restaurantId,
    },
  }),
  fetchWlUrlSuccess: (menu) => ({
    type: menuActions.FETCH_WLURL_SUCCESS,
    payload: menu,
  }),
  fetchWlUrlFailure: (error) => ({
    type: menuActions.FETCH_WLURL_FAILURE,
    payload: error,
  }),

  fetchPOSExportStatusStart: (brandId, restaurantId) => ({
    type: menuActions.FETCH_POS_EXPORT_STATUS_START,
    payload: {
      brandId,
      restaurantId,
    },
  }),
  fetchPOSExportStatusSuccess: (menu) => ({
    type: menuActions.FETCH_POS_EXPORT_STATUS_SUCCESS,
    payload: menu,
  }),
  fetchPOSExportStatusFailure: (error) => ({
    type: menuActions.FETCH_POS_EXPORT_STATUS_FAILURE,
    payload: error,
  }),

  fetchPOSExportStart: (brandId, restaurantId) => ({
    type: menuActions.FETCH_POS_EXPORT_START,
    payload: {
      brandId,
      restaurantId,
    },
  }),
  fetchPOSExportSuccess: (menu) => ({
    type: menuActions.FETCH_POS_EXPORT_SUCCESS,
    payload: menu,
  }),
  fetchPOSExportFailure: (error) => ({
    type: menuActions.FETCH_POS_EXPORT_FAILURE,
    payload: error,
  }),

  fetchCreateQRStart: (qrData) => ({
    type: menuActions.FETCH_CREATE_QR_START,
    payload: qrData,
  }),
  fetchCreateWLStart: (wlData) => ({
    type: menuActions.FETCH_CREATE_WL_START,
    payload: wlData,
  }),
  fetchCreateWLSuccess: () => ({
    type: menuActions.FETCH_CREATE_WL_SUCCESS,
  }),
  fetchCreateWLFailure: (error) => ({
    type: menuActions.FETCH_CREATE_WL_FAILURE,
    payload: error,
  }),

  setQrMenuProperty: (status, menuId) => ({
    type: menuActions.SET_QR_MENU_PROPERTY,
    payload: { status, menuId },
  }),
  setQrMenuPropertySuccess: (status) => ({
    type: menuActions.SET_QR_MENU_PROPERTY_SUCCESS,
    payload: { status },
  }),
  setQrMenuPropertyFailure: (error) => ({
    type: menuActions.SET_QR_MENU_PROPERTY_FAILURE,
    payload: error,
  }),
};

export default menuActions;
