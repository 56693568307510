import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import siteConfig from '@iso/config/site.config';

const onRKeeperSettingsRequest = async (userId) => {
  const response = await fetch(`${siteConfig.apiUrl}/rkStationData/${userId}`);
  return response.json();
};

function* fetchRKeeperSettings(action) {
  try {
    let rKeeperSettings = yield call(onRKeeperSettingsRequest, action.payload);
    yield put(
      actions.fetchRKeeperSettingsSuccess(action.payload, rKeeperSettings)
    );
  } catch (error) {
    yield put(actions.fetchRKeeperSettingsFailure(error));
  }
}

const submitRKeeperSettingsRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/rkStationData/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* submitRKeeperSettings(action) {
  try {
    let res = yield call(submitRKeeperSettingsRequest, action.payload);
    yield put(actions.submitRKeeperSettingsSuccess(action.payload.userId, res));
  } catch (error) {
    yield put(actions.submitRKeeperSettingsFailure(error));
  }
}

const createRKeeperSettingsRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/rkStationData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* createRKeeperSettings(action) {
  try {
    let res = yield call(createRKeeperSettingsRequest, action.payload);
    yield put(actions.submitRKeeperSettingsSuccess(action.payload.userId, res));
  } catch (error) {
    yield put(actions.submitRKeeperSettingsFailure(error));
  }
}

const submitIikoSettingsRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/iikoData/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* submitIikoSettings(action) {
  try {
    let res = yield call(submitIikoSettingsRequest, action.payload);
    yield put(actions.submitIikoSettingsSuccess(action.payload.userId, res));
  } catch (error) {
    yield put(actions.submitIikoSettingsFailure(error));
  }
}

const createIikoSettingsRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/iikoData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* createIikoSettings(action) {
  try {
    let res = yield call(createIikoSettingsRequest, action.payload);
    yield put(actions.submitIikoSettingsSuccess(action.payload.userId, res));
  } catch (error) {
    yield put(actions.submitIikoSettingsFailure(error));
  }
}

async function fetchPartnerSettingsRequest(userId) {
  const response = await fetch(
    `${siteConfig.apiUrl}/partnerSettings/${userId}`
  );
  return response.json();
}

function* fetchPartnerSettings(action) {
  try {
    const partnerSettings = yield call(
      fetchPartnerSettingsRequest,
      action.payload
    );
    yield put(
      actions.fetchPartnerSettingsSuccess(
        action.payload.userId,
        partnerSettings
      )
    );
  } catch (error) {
    yield put(actions.fetchPartnerSettingsFailure(error));
  }
}

const submitPartnerSettingsRequest = async (partnerSettings) => {
  const response = await fetch(`${siteConfig.apiUrl}/partnerSettings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(partnerSettings),
    crossDomain: true,
  });
  return response.json();
};

function* submitPartnerSettings(action) {
  try {
    const data = action.payload.data;
    const userId = data.brand_id;

    const settingsDataRequest = {
      ...data,
      id: userId,
    };
    console.log('settingsDataRequest', settingsDataRequest);
    yield call(submitPartnerSettingsRequest, settingsDataRequest);

    const siteDataRequest = {
      id: userId,
      user_id: userId,
      brand_id: 1,
      restaurant_id: data.restaurant_id,
      restaurant_lat: data.restaurant_address_yandex?.lat.toString(),
      restaurant_lon: data.restaurant_address_yandex?.long.toString(),
      restaurant_address: data.restaurant_address_yandex?.addressLine,
      courier_phone_number:
        (data.courier_phone_number.indexOf('+') === -1 ? '+' : '') +
        data.courier_phone_number,
      buyer_phone_number:
        (data.buyer_phone_number.indexOf('+') === -1 ? '+' : '') +
        data.buyer_phone_number,
      buyer_phone_number_whatsapp:
        (data.buyer_phone_number_whatsapp.indexOf('+') === -1 ? '+' : '') +
        data.buyer_phone_number_whatsapp,
      mail_for_incoming_orders: data.mail_for_incoming_orders,
      legal_entity_of_restaurant: data.legal_entity_of_restaurant,
      inn: data.inn,
      ogrn: data.ogrn,
      bank: data.bank,
      city: data.city,
      checking_account: data.checking_account,
      restaurant_name: data.restaurantName,
      company_name: data.company_name,
      comments_how_to_find_restaurant: data.comments_how_to_find_restaurant,
      token: data.token,
      available_delivery_method: data.available_delivery_method,
      serviceId: data.serviceId,
    };

    console.log('siteDataRequest', siteDataRequest);
    let siteData = yield call(submitCreateSiteRequest, siteDataRequest);

    yield put(
      actions.submitPartnerSettingsSuccess(action.payload.partnerSettings)
    );
  } catch (error) {
    yield put(actions.submitPartnerSettingsFailure(error));
  }
}

const submitPartnerPlanRequest = async (tariffPlanData) => {
  const response = await fetch(`${siteConfig.apiUrl}/partnerTariff`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(tariffPlanData),
    crossDomain: true,
  });
  return response.json();
};

const submitLearningStepRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/partnerLearning`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* submitPartnerPlan(action) {
  try {
    let settings = yield call(submitPartnerPlanRequest, {
      id: action.payload.userId,
      ...action.payload.data,
    });
    const stepData = action.payload.stepData;
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: action.payload.userId,
        learningStep: stepData.step,
      });
    }
    yield put(
      actions.submitPartnerPlanSuccess(action.payload.userId, settings)
    );
    action.payload.history?.push(`/intro/${stepData.step}`);
    // history.push();
  } catch (error) {
    yield put(actions.submitPartnerSettingsFailure(error));
  }
}

async function fetchBrandSettingsRequest(userId, restaurantId) {
  const response = await fetch(
    `${siteConfig.apiUrl}/wlBrandSettings?user_id=${userId}&restaurant_id=${restaurantId}`
  );
  return response.json();
}

function* fetchBrandSettingsStart(action) {
  try {
    const { userId, restaurantId } = action.payload;
    let settings = yield call(fetchBrandSettingsRequest, userId, restaurantId);

    yield put(actions.fetchBrandSettingsSuccess(userId, settings));
  } catch (error) {
    yield put(actions.submitPartnerSettingsFailure(error));
  }
}

async function submitPartnerInialsRequest(initialsData) {
  const response = await fetch(`${siteConfig.apiUrl}/partnerInitials`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(initialsData),
    crossDomain: true,
  });
  return response.json();
}

async function submitCreateSiteRequest(createSiteData) {
  const response = await fetch(`${siteConfig.apiUrl}/wlBrandSettings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(createSiteData),
    crossDomain: true,
  });
  return response.json();
}

export const onCreateWL = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/createwl`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

export const onSiteDomainWL = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/wlSiteDomain`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* submitCreateSite(action) {
  try {
    const userId = action.payload.userId;
    const data = action.payload.data;

    const initialsData = {
      id: userId,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
    };
    let settings = yield call(submitPartnerInialsRequest, initialsData);

    const siteDataRequest = {
      user_id: userId,
      brand_id: 1,
      restaurant_id: data.restaurant_id,
      restaurant_lat: data.restaurant_address_yandex?.lat.toString(),
      restaurant_lon: data.restaurant_address_yandex?.long.toString(),
      restaurant_address: data.restaurant_address_yandex?.addressLine,
      courier_phone_number:
        (data.courier_phone_number.indexOf('+') === -1 ? '+' : '') +
        data.courier_phone_number,
      buyer_phone_number:
        (data.buyer_phone_number.indexOf('+') === -1 ? '+' : '') +
        data.buyer_phone_number,
      buyer_phone_number_whatsapp:
        (data.buyer_phone_number_whatsapp.indexOf('+') === -1 ? '+' : '') +
        data.buyer_phone_number_whatsapp,
      mail_for_incoming_orders: data.mail_for_incoming_orders,
      legal_entity_of_restaurant: data.legal_entity_of_restaurant,
      inn: data.inn,
      ogrn: data.ogrn,
      bank: data.bank,
      city: data.city,
      checking_account: data.checking_account,
      restaurant_name: data.restaurant_name,
      company_name: data.company_name,
      comments_how_to_find_restaurant: data.comments_how_to_find_restaurant,
      token: data.token,
      available_delivery_method: data.available_delivery_method,
      serviceId: data.serviceId,
    };
    let siteData = yield call(submitCreateSiteRequest, siteDataRequest);

    const stepData = action.payload.stepData;
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: userId,
        learningStep: stepData.step,
      });
    }
    yield put(actions.submitCreateSiteSuccess(userId, settings));

    action.payload.history?.push(`/intro/${stepData.step}`);
    // history.push(`/intro/${stepData.step}`);
  } catch (error) {
    yield put(actions.submitCreateSiteFailure(error));
  }
}

function* submitCreateMenu(action) {
  try {
    const userId = action.payload.userId;
    const data = action.payload.data;

    const stepData = action.payload.stepData;
    let settings = {
      id: userId,
      learningStep: stepData.step,
    };
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: userId,
        learningStep: stepData.step,
      });
    }
    yield put(actions.submitCreateMenuSuccess(userId, settings));

    action.payload.history?.push(`/intro/${stepData.step}`);

    // history.push(`/intro/${stepData.step}`);
  } catch (error) {
    yield put(actions.submitCreateMenuFailure(error));
  }
}

function* submitFillBrand(action) {
  try {
    const userId = action.payload.userId;

    const stepData = action.payload.stepData;
    let settings = {
      id: userId,
      learningStep: stepData.step,
    };
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: userId,
        learningStep: stepData.step,
      });
    }
    yield put(actions.submitFillBrandSuccess(userId, settings));

    action.payload.history?.push(`/intro/${stepData.step}`);
  } catch (error) {
    yield put(actions.submitCreateMenuFailure(error));
  }
}

function* submitSiteAddress(action) {
  try {
    const userId = action.payload.userId;
    const stepData = action.payload.stepData;
    const wlData = action.payload.wlData;

    let settings = {
      id: userId,
      learningStep: stepData.step,
    };
    if (stepData.save) {
      settings = yield call(submitLearningStepRequest, {
        id: userId,
        learningStep: stepData.step,
      });
    }
    yield call(onCreateWL, wlData);

    yield put(actions.submitSiteAddressSuccess(userId, settings));

    console.log(action);
    action.payload.history?.push(`/intro/${stepData.step}`);
  } catch (error) {
    yield put(actions.submitCreateMenuFailure(error));
  }
}

function* submitSiteAddressDomain(action) {
  try {
    const wlData = action.payload.wlData;

    yield call(onSiteDomainWL, wlData);
    yield put(actions.submitSiteAddressDomainSuccess(wlData.userId, wlData));
  } catch (error) {
    yield put(actions.submitCreateMenuFailure(error));
  }
}

const onIikoSettingsRequest = async (restaurantId) => {
  const response = await fetch(
    `${siteConfig.apiUrl}/iikoData?restaurant_id=${restaurantId}`
  );
  return response.json();
};

function* fetchIikoSettings(action) {
  try {
    let iikoSettings = yield call(onIikoSettingsRequest, action.payload);
    yield put(actions.fetchIikoSettingsSuccess(action.payload, iikoSettings));
  } catch (error) {
    yield put(actions.fetchIikoSettingsFailure(error));
  }
}

const onPOSIntegrationRequest = async (restaurantId) => {
  const response = await fetch(
    `${siteConfig.apiUrl}/restaurantPosIntegrations?restaurant_id=${restaurantId}`
  );
  return response.json();
};

function* fetchPOSIntegration(action) {
  try {
    let posIntegration = yield call(onPOSIntegrationRequest, action.payload);
    yield put(
      actions.fetchPOSIntegrationSuccess(
        action.payload,
        posIntegration?.pos_integrations
      )
    );
  } catch (error) {
    yield put(actions.fetchPOSIntegrationFailure(error));
  }
}

export default function* accountSettingsSaga() {
  yield all([
    yield takeEvery(actions.FETCH_RKEEPER_SETTINGS_START, fetchRKeeperSettings),
    yield takeEvery(
      actions.CREATE_RKEEPER_SETTINGS_START,
      createRKeeperSettings
    ),
    yield takeEvery(
      actions.SUBMIT_RKEEPER_SETTINGS_START,
      submitRKeeperSettings
    ),
    yield takeEvery(actions.FETCH_PARTNER_SETTINGS_START, fetchPartnerSettings),
    yield takeEvery(
      actions.SUBMIT_PARTNER_SETTINGS_START,
      submitPartnerSettings
    ),
    yield takeEvery(actions.SUBMIT_PARTNER_PLAN_START, submitPartnerPlan),
    yield takeEvery(
      actions.FETCH_BRAND_SETTINGS_START,
      fetchBrandSettingsStart
    ),
    yield takeEvery(actions.SUBMIT_CREATE_SITE_START, submitCreateSite),
    yield takeEvery(actions.SUBMIT_CREATE_MENU_START, submitCreateMenu),
    yield takeEvery(actions.SUBMIT_FILL_BRAND_START, submitFillBrand),
    yield takeEvery(actions.SUBMIT_SITE_ADDRESS_START, submitSiteAddress),
    yield takeEvery(
      actions.SUBMIT_SITE_ADDRESS_DOMAIN_START,
      submitSiteAddressDomain
    ),
    yield takeEvery(actions.FETCH_IIKO_SETTINGS_START, fetchIikoSettings),
    yield takeEvery(actions.CREATE_IIKO_SETTINGS_START, createIikoSettings),
    yield takeEvery(actions.SUBMIT_IIKO_SETTINGS_START, submitIikoSettings),
    yield takeEvery(actions.FETCH_POS_INTEGRATION_START, fetchPOSIntegration),
  ]);
}
