// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import stopListsActions from './actions';
import stopListsData from './stopLists.data';
import siteConfig from '@iso/config/site.config';
import { db } from '@iso/lib/firebase/firebase';

export const onMenuRequest = async (brandId) =>
  await fetch(
    `${siteConfig.apiUrl}/${brandId}/stopLists/${localStorage.getItem(
      'restaurantId'
    )}`
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onAddToStopListsRequest = async (restaurantId, productId) =>
  await fetch(`${siteConfig.apiUrl}/stopLists/${restaurantId}/${productId}`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onRemoveFromStopListsRequest = async (restaurantId, productId) =>
  await fetch(`${siteConfig.apiUrl}/stopLists/${restaurantId}/${productId}`, {
    crossDomain: true,
    method: 'DELETE',
    // headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    // },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchStopListsDataEffect({ payload }) {
  try {
    let StopLists = yield call(onMenuRequest, payload);
    yield put(stopListsActions.fetchStopListsDataSuccess(StopLists));
  } catch (error) {
    yield put(stopListsActions.fetchStopListsDataFailure(error));
  }
}

function* fetchStopListsAddData({ payload }) {
  try {
    const { id, status, restaurantId } = payload;

    if (!status) {
      yield call(onAddToStopListsRequest, restaurantId, id);
    } else {
      yield call(onRemoveFromStopListsRequest, restaurantId, id);
    }
    yield put(
      stopListsActions.setStopListsItemSuccess(id, status, restaurantId)
    );
  } catch (error) {
    yield put(stopListsActions.setStopListsItemFailure(error));
  }
}

export const onFetchToStopListsItemRestaurants = async (productId) =>
  await fetch(`${siteConfig.apiUrl}/productStopLists/${productId}`, {
    crossDomain: true,
    method: 'GET',
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchStopListsItemRestaurants({ payload }) {
  const productId = payload;
  try {
    const restaurants = yield call(
      onFetchToStopListsItemRestaurants,
      productId
    );
    yield put(
      stopListsActions.fetchStopListsItemRestaurantsSuccess(
        productId,
        restaurants
      )
    );
  } catch (error) {
    yield put(
      stopListsActions.fetchStopListsItemRestaurantsFailure(productId, error)
    );
  }
}

function* setStopListItemRestaurant({ payload }) {
  const { productId, status, restaurantId } = payload;

  try {
    if (status) {
      yield call(onAddToStopListsRequest, restaurantId, productId);
    } else {
      yield call(onRemoveFromStopListsRequest, restaurantId, productId);
    }
    yield put(
      stopListsActions.setStopListItemRestaurantSuccess(
        productId,
        restaurantId,
        status
      )
    );
  } catch (error) {
    yield put(
      stopListsActions.setStopListItemRestaurantFailure(
        productId,
        restaurantId,
        error
      )
    );
  }
}

export default function* stopListsSaga() {
  yield all([
    takeEvery(
      stopListsActions.FETCH_STOP_LISTS_DATA_START,
      fetchStopListsDataEffect
    ),
    takeEvery(stopListsActions.SET_STOP_LISTS_ITEM, fetchStopListsAddData),
    takeEvery(
      stopListsActions.FETCH_STOP_LISTS_ITEM_RESTAURANTS,
      fetchStopListsItemRestaurants
    ),
    takeEvery(
      stopListsActions.SET_STOP_LIST_ITEM_RESTAURANT,
      setStopListItemRestaurant
    ),
  ]);
}
